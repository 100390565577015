var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "sign-up-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validateAndSignUp.apply(null, arguments)
        },
      },
    },
    [
      _c("FormTextField", {
        ref: "email-address-field",
        attrs: {
          "input-mode": "email",
          "is-lower-case": "",
          "is-trimmed": "",
          label: "Email address",
          normalization: "NFC",
          note: "Lowercase only",
          "should-be-email-address": "",
          "should-be-present": "",
          "validation-state": _vm.validationGroup.emailAddress,
        },
        on: { return: _vm.validateAndSignUp },
        model: {
          value: _vm.emailAddress,
          callback: function ($$v) {
            _vm.emailAddress = $$v
          },
          expression: "emailAddress",
        },
      }),
      _vm._v(" "),
      _c("FormSubmit", {
        ref: "sign-up-button",
        attrs: {
          "is-disabled":
            _vm.validationGroup.isEngaged && !_vm.validationGroup.isValid,
          label: "Sign up",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }