<!-- See app/views/administrators/log_in.html.erb. -->

<template>
  <div class="log-in-content">
    <h1>Log in</h1>
    <form @submit.prevent="validateAndLogIn">
      <FormTextField
	ref="username-field"
	is-trimmed
	label="Username"
	should-be-present
	:validation-state="validationGroup.username"
	v-model="username"
	@return="forms.focus('password-field')"
      />
      <FormPasswordField
	ref="password-field"
	is-trimmed
	label="Password"
	should-be-present
	:validation-state="validationGroup.password"
	v-model="password"
	@return="validateAndLogIn"
      />
      <FormSubmit
	ref="log-in-button"
	:is-disabled="validationGroup.isEngaged && !validationGroup.isValid"
	label="Log in"
      />
    </form>
  </div>
</template>

<script>
import FormPasswordField from "../shared/forms/FormPasswordField";
import FormSubmit from "../shared/forms/FormSubmit";
import FormTextField from "../shared/forms/FormTextField";

import dialogs from "../shared/dialogs/dialogs";

import forms from "../shared/forms/forms";

import ValidationGroup from "../shared/forms/ValidationGroup";

export default {
  name: "LogInContent",
  components: {FormPasswordField, FormSubmit, FormTextField},
  mixins: [dialogs, forms],
  data() { return {password: null, username: null, validationGroup: new ValidationGroup(["password", "username"])}; },
  mounted() {
    this.forms.focus("username-field");
    //v Convenient for debugging in a browser console.
    window.logInContent = this;
  },
  beforeDestroy() { delete window.logInContent; },
  methods: {
    validateAndLogIn() {
      this.validationGroup.engage();
      if (!this.validationGroup.isValid) return;
      Axios.post(paths.LOG_IN_FOR_VISITOR, utilities.ajaxParams({password: this.password, username: this.username}))
	.then(() => { Turbolinks.visit(paths.ROOT); })
	.catch((error) => { this.dialogs.ajaxError(error); });
    }
  }
};
</script>
