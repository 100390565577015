// The contents of this file are very miscellaneous, so its organization is ad hoc. Webpack compiles it to a pack that is loaded (maybe from browser cache) on
// every true page load (not pseudo page load via Turbolinks - the terminology is tortuous).



// IMPORT AND CONFIGURE


// Axios

import Axios from "axios";

Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Axios.interceptors.response.use(
  undefined,
  //v This interceptor handles the unusual situation that an AJAX request fails in a way which isn't specific to the call chain that generated the request (and
  //v hence shouldn't be handled by the chain) and requires a transition to another page. The main example is session termination, where the request includes an
  //v administrator ID (in a session cookie), but the administrator doesn't exist (which shouldn't happen, but if it does, it's handled gracefully); see the
  //v get_administrator before filter defined in app/controllers/administrators_controller.rb.
  function(error) {
    if (error.response.data.redirect) {
      Turbolinks.visit(error.response.data.nextPath);
      //v The error message should be in flash[:error]. It will be displayed after the visit.
      error.response.data.noMessage = true;
    }
    return Promise.reject(error);
  }
);

document.addEventListener(
  "turbolinks:load",
  function() {
    const csrfTokenEl = document.querySelector("meta[name='csrf-token']");
    if (csrfTokenEl)
      //^ Paranoia.
      Axios.defaults.headers.common["X-CSRF-Token"] = csrfTokenEl.getAttribute("content");
  }
);


// Entry components

const entryComponentsContext = require.context("../vues", true, /\.entry\.vue$/);


// Manifest utilities.

import * as manifestUtilities from "../javascripts/manifest_utilities";


// Stylesheets

import "../stylesheets/application";


// SVGs

import * as svgs from "javascripts/svgs";


// Turbolinks

import Turbolinks from "turbolinks-rh";


// Utilities

import * as utilities from "../javascripts/utilities";


// Vue

import Vue from "vue";


// Vuex

import store from "../vues/store";



// INJECT


// Axios

window.Axios = Axios;


// Entry components

utilities.forEach(
  entryComponentsContext.keys(),
  function(path) {
    const options = entryComponentsContext(path).default;
    Vue.component(options.name, options);
  }
);


// Manifest utilities.

window.manifestUtilities = manifestUtilities;


// SVGs

window.svgs = svgs;

//v For use in templates.
Vue.mixin({created() { this.svgs = svgs; }});


// Turbolinks

//v Convenient for debugging in a browser console.
window.tlc = function() { return Turbolinks.controller.cache.snapshots; };


// Utilities

window.utilities = utilities;

//v For use in templates.
Vue.mixin({created() { this.utilities = utilities; }});


// Vue

window.Vue = Vue;



// INITIALIZE


// Scrollbar width

document.addEventListener(
  "DOMContentLoaded",
  function() {
    let
      jigEl,
      scrollbarWidth;
    document.body.insertAdjacentHTML("afterbegin", '<div id="jig" style="position: absolute; top: -99px; overflow: scroll">jig</div>');
    jigEl = document.body.querySelector("#jig");
    scrollbarWidth = utilities.scrollbarWidth(jigEl);
    document.documentElement.scrollbarWidth = scrollbarWidth;
    document.documentElement.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
    document.documentElement.style.setProperty("--scrollbar-width-is-zero", scrollbarWidth === 0 ? "1" : "0");
    jigEl.remove();
  }
);


// Viewport height

//v See
//v
//v https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
//v
//v and ../stylesheets/html.scss.
window.addEventListener("resize", utilities.debounce(function() { document.documentElement.style.setProperty("--vh", `${0.01*window.innerHeight}px`); }, 10));


// Content replacement

//v This handler must fire before the one under "Content scrolling" below, else that handler operates on a content-wrapper element that is immediately removed
//v from the DOM.
document.addEventListener(
  "turbolinks:load",
  function() {
    new Vue(
      {
	//v See app/views/layouts/application.html.erb.
	el: "#vue-mount-point",
	store,
	beforeMount() { this.placeholderOuterHtml = this.$el.outerHTML; },
	mounted() {
	  //v In this context, () => { this.$destroy(); } isn't equivalent to this.$destroy, presumably for reasons related to the warning about arrow functions
	  //v under
	  //v
	  //v https://vuejs.org/v2/guide/instance.html#Instance-Lifecycle-Hooks .
	  document.addEventListener("turbolinks:visit", () => { this.$destroy(); }, {once: true});
	},
	destroyed() { this.$el.outerHTML = this.placeholderOuterHtml; }
      }
    );
  }
);


// Content scrolling

//v This handler must fire after the one under "Content replacement" above, else this handler operates on a content-wrapper element that is immediately removed
//v from the DOM.
document.addEventListener(
  "turbolinks:load",
  function() {
    const contentWrapperEl = document.querySelector("#content-wrapper");
    store.dispatch("scrollableElements/addScrollableElement", contentWrapperEl);
    //v TODO: Clumsy. The scrollableElements store is designed for use in components, which can dispatch the deleteScrollableElement action from beforeDestroy
    //v handlers.
    document.addEventListener("turbolinks:visit", function() { store.dispatch("scrollableElements/deleteScrollableElement", contentWrapperEl); }, {once: true});
  }
);


// Page transitions

Turbolinks.start();

//v See ../stylesheets/content.scss.
document.addEventListener(
  "DOMContentLoaded",
  function() {
    store.dispatch(
      "pageTransitions/addEnterProcessor",
      function() {
	const contentEl = document.querySelector("#content");
	return new Promise(
	  function(resolve) {
	    //v Delay is needed, at least sometimes, for reasons unclear.
	    setTimeout(function() { utilities.addClass("is-visible", contentEl); });
	    contentEl.addEventListener("transitionend", resolve, {once: true});
	  }
	);
      }
    );
    store.dispatch("pageTransitions/addLeaveProcessor", manifestUtilities.closeIfPresentAndOpenOnPageLeave);
    store.dispatch(
      "pageTransitions/addLeaveProcessor",
      function() {
	const contentEl = document.querySelector("#content");
	return new Promise(
	  function(resolve) {
	    utilities.removeClass("is-visible", contentEl);
	    contentEl.addEventListener("transitionend", resolve, {once: true});
	  }
	);
      }
    );
  }
);

document.addEventListener("turbolinks:load", function() { store.dispatch("pageTransitions/doEnter"); });

function doLeave(event) {
  event.suspendVisit();
  store.dispatch("pageTransitions/doLeave", event.continueVisit);
};

document.addEventListener("turbolinks:before-visit", doLeave);
document.addEventListener("turbolinks:before-restoration-visit", doLeave);



// DEBUG

// document.addEventListener("turbolinks:click", function() { console.log("CLICK"); });
// document.addEventListener("turbolinks:before-visit", function() { console.log("BEFORE-VISIT"); });
// document.addEventListener("turbolinks:before-restoration-visit", function() { console.log("BEFORE-RESTORATION-VISIT"); });
// document.addEventListener("turbolinks:visit", function() { console.log("VISIT"); });
// document.addEventListener("turbolinks:request-start", function() { console.log("REQUEST-START"); });
// document.addEventListener("turbolinks:request-end", function() { console.log("REQUEST-END"); });
// document.addEventListener("turbolinks:before-cache", function() { console.log("BEFORE-CACHE"); });
// document.addEventListener("turbolinks:before-render", function() { console.log("BEFORE-RENDER"); });
// document.addEventListener("turbolinks:render", function() { console.log("RENDER"); });
// document.addEventListener("turbolinks:load", function() { console.log("LOAD"); });
