// This file is meant to be mixed into formVisorables; see ./README.

import ShadeTopTransition from "../transitions/ShadeTopTransition";

import formInput from "./formInput";

export default {
  components: {ShadeTopTransition},
  mixins: [formInput],
  props: {
    //v Given a reactive datum visorIf, one might reasonably expect
    //v
    //v <SomeFormVisorable ...>
    //v   <template v-if="visorIf" #visor-content>
    //v     ...
    //v   </template>
    //v </SomeFormVisorable>
    //v
    //v to display the visor when visorIf is truthy and not when visorIf is falsey. Alas, one would be mistaken. Initially, the visor is indeed displayed or not
    //v according to visorIf. Moreover, if visorIf first is truthy, hence the visor is displayed, and visorIf later becomes falsey, then the visor gets removed.
    //v Subsequently, however, the visor doesn't respond to changes in visorIf, for reasons unclear.
    //v
    //v Accordingly, visorIf must be passed as a prop, so that
    //v
    //v <SomeFormVisorable :visor-if="visorIf" ...>
    //v   <template #visor-content>
    //v     ...
    //v   </template>
    //v </SomeFormVisorable>
    //v
    //v has the desired effect.
    visorIf: {type: Boolean, required: false, default: true}
  },
  computed: {
    visorContentSlotIsFilled() { return !!this.$slots["visor-content"] && !!this.$slots["visor-content"][0]; },
    visorIsPresent() { return this.visorIf && this.visorContentSlotIsFilled; }
  }
};
