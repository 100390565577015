// This class is meant to be invoked by the ValidationGroup class. (For a form containing a single input component, an application component could invoke this
// class directly, rather than indirectly via ValidationGroup, but for uniformity, this practice isn't recommended.)

//v Should match assumptions of the FormErrorTip template and the method fV_validate defined in ./formValidation.js.
export default class {
  constructor() {
    this.atLeast = null;
    this.atMost = null;
    this.failsAtLeast = false;
    this.failsAtMost = false;
    this.failsChecked = false;
    this.failsEmailAddress = false;
    this.failsInteger = false;
    this.failsLengthAtLeast = false;
    this.failsLengthAtMost = false;
    this.failsNonnegative = false;
    this.failsPositive = false;
    this.failsPresent = false;
    this.isEngaged = false;
    this.isValid = true;
    this.lengthAtLeast = null;
    this.lengthAtMost = null;
  }
  disengage() { this.isEngaged = false; }
  engage() { this.isEngaged = true; }
};
