<!-- This component is meant to be invoked by the tips mixin. -->

<template>
  <transition name="fade-fast" @after-leave="selfDestruct">
    <!--^ This component contains a potentially scrollable element (the paragraphed-string element), but there's no need to add the element to the       -->
    <!--^ scrollable-elements store (via an after-enter handler on the transition), because there won't be any further such components beyond this one - -->
    <!--^ once a tip is open, nothing else will open (or close) until it closes.                                                                         -->
    <div
      v-if="isVisible"
      class="tip"
      :class="{
	'opens-downward': !opensUpward,
	'opens-leftward': opensLeftward,
	'opens-rightward': !opensLeftward,
	'opens-upward': opensUpward,
	'spur-is-at-center-of-opener': !spurIsAtEdgeOfOpener,
	'spur-is-at-edge-of-opener': spurIsAtEdgeOfOpener
      }"
      @click.stop
    >
      <!--^ When the tip function in ./tips.js is used as a click handler, propagation stoppage is needed, else a click within the tip propagates to the -->
      <!--^ opener and reopens the tip.                                                                                                                  -->
      <div class="paragraphed-string" v-html="paragraphedString"/>
    </div>
  </transition>
</template>

<script>
const
  BEGIN_P = "<p>",
  END_P = "</p>";

export default {
  //v TipComponent rather than Tip, because single-word component names can be problematic.
  name: "TipComponent",
  props: {
    //v Should be given if opensUpward is true.
    bottom:               {type: Number,   required: false},
    //v Should be given if opensLeftward is false.
    left:                 {type: Number,   required: false},
    maxHeight:            {type: Number,   required: true },
    offsetBottom:         {type: String,   required: true },
    offsetLeft:           {type: String,   required: true },
    offsetRight:          {type: String,   required: true },
    offsetTop:            {type: String,   required: true },
    //v The opener element is an instance of Object. Regrettably, if "type: Object" is specified, then Vue doesn't use instanceof to check the type. Instead, it
    //v checks that the constructor is Object, which it isn't - it's something such as HTMLDivElement. Moreover, specifying in advance all the constructors that
    //v may arise in practice is impossible.
    openerEl:             {                required: true },
    opensLeftward:        {type: Boolean,  required: true },
    opensUpward:          {type: Boolean,  required: true },
    resolve:              {type: Function, required: true },
    //v Should be given if opensLeftward is true.
    right:                {type: Number,   required: false},
    spurIsAtEdgeOfOpener: {type: Boolean,  required: true },
    string:               {type: String,   required: true },
    //v Should be given if opensUpward is false.
    top:                  {type: Number,   required: false}
  },
  data() { return {isVisible: false}; },
  computed: {paragraphedString() { return BEGIN_P+this.string.replace(/(\r?\n)+/g, END_P+BEGIN_P)+END_P; }},
  mounted() {
    utilities.forEach(document.querySelectorAll("input"), function(inputEl) { inputEl.blur(); });
    this.isVisible = true;
    this.$nextTick(this.setPositionAndHeight);
    this.$store.dispatch("scrollableElements/addFreezeRequest", this);
    window.addEventListener("resize", this.debouncedClose);
    document.addEventListener("click", this.closeIfClickIsOutside, {capture: true});
  },
  beforeDestroy() {
    this.$store.dispatch("scrollableElements/deleteFreezeRequest", this);
    window.removeEventListener("resize", this.debouncedClose);
    document.removeEventListener("click", this.closeIfClickIsOutside, {capture: true});
  },
  methods: {
    close() {
      this.isVisible = false;
      this.resolve();
    },
    closeIfClickIsOutside(event) {
      if (event.target.closest(".tip") !== this.$el) {
	this.close();
	//v Keep the opener from reopening the tip.
	if (this.openerEl.contains(event.target)) event.stopPropagation();
      }
    },
    debouncedClose() { utilities.debounce(() => { this.close(); }, 10)(); },
    selfDestruct() {
      this.$destroy();
      this.$el.parentElement.removeChild(this.$el);
    },
    setPositionAndHeight() {
      const style = this.$el.style;
      if (this.bottom) style.setProperty("--bottom", `${this.bottom}px`);
      if (this.top) style.setProperty("--top", `${this.top}px`);
      if (this.left) style.setProperty("--left", `${this.left}px`);
      if (this.right) style.setProperty("--right", `${this.right}px`);
      style.setProperty("--offset-top", this.offsetTop);
      style.setProperty("--offset-bottom", this.offsetBottom);
      style.setProperty("--offset-right", this.offsetRight);
      style.setProperty("--offset-left", this.offsetLeft);
      if (this.maxHeight) style.setProperty("--max-height", `${this.maxHeight}px`);
    }
  }
};
</script>

<style lang="scss" scoped>
.tip {
  /*v --spur-height is sqrt(3)/2 times --spur-width. */
  --spur-height: 0.405949rem; /* 6.49519px */
  --spur-width: 0.46875rem; /* 7.5px */
  @screen tablet {
    --spur-height: 0.541266rem; /* 8.66025px */
    --spur-width: 0.625rem; /* 10px */
  }

  @apply absolute;
  /* If needed, the z-index should be specified by a rule with this selector in the parent component. */
  /*v help-tip--background is a medium gray, so the standard drop-shadows look a little smudgy. */
  @apply help-tip-drop-shadows;
  @apply cursor-default w-max;
  /*v Second argument should be 67% of max-width of #content; see ../../../stylesheets/content.scss. */
  max-width: calc(min(67vw, 31rem)); /* 496px */
  @screen tablet {
    /*v Second argument should be 50% of max-width of #content; see ../../../stylesheets/content.scss. */
    max-width: calc(min(50vw, 23rem)); /* 368px */
  }
  border-radius: 0.1875rem; /* 3px */
  @apply bg-help-tip--background;

  &::before {
    content: "";
    @apply absolute;
    z-index: -1;
    height: calc(1px + var(--spur-height));
    width: var(--spur-width);
    @apply bg-help-tip--background;
  }

  &.opens-downward {
    top: calc(var(--top) + var(--offset-bottom) + var(--spur-height));

    &::before {
      clip-path: polygon(0 100%, 0 calc(100% - 1px), 50% 0, 100% calc(100% - 1px), 100% 100%);
      bottom: calc(100% - 1px);
    }
  }

  &.opens-upward {
    bottom: calc(var(--bottom) + var(--offset-top) + var(--spur-height));

    &::before {
      clip-path: polygon(0 0, 0 1px, 50% 100%, 100% 1px, 100% 0);
      top: calc(100% - 1px);
    }
  }

  &.opens-rightward {
    &.spur-is-at-center-of-opener {
      left: calc(var(--left) + var(--offset-right) - var(--spur-width)/2);
    }

    &.spur-is-at-edge-of-opener {
      left: calc(var(--left) + var(--offset-right));
    }

    &::before {
      @apply left-0;
    }
  }

  &.opens-leftward {
    &.spur-is-at-center-of-opener {
      right: calc(var(--right) + var(--offset-left) - var(--spur-width)/2);
    }

    &.spur-is-at-edge-of-opener {
      right: calc(var(--right) + var(--offset-left));
    }

    &::before {
      @apply right-0;
    }
  }

  &.opens-downward {
    &.opens-rightward {
      @apply rounded-tl-none;
    }

    &.opens-leftward {
      @apply rounded-tr-none;
    }
  }

  &.opens-upward {
    &.opens-rightward {
      @apply rounded-bl-none;
    }

    &.opens-leftward {
      @apply rounded-br-none;
    }
  }

  .paragraphed-string {
    /*v The 1rem is a bottom or top margin. */
    max-height: calc(var(--max-height) - var(--spur-height) - 1rem); /* 16px */
    @apply overflow-auto overscroll-none;
    padding: 0.328125rem 0.4375rem 0.28125rem; /* 5.25px 7px 4.5px */
    @screen tablet {
      padding: 0.4375rem 0.5625rem 0.375rem; /* 7px 9px 6px */
    }

    /deep/ p {
      margin-top: 0.375rem; /* 6px */
      @screen tablet {
	margin-top: 0.5rem; /* 8px */
      }
      @apply cursor-text text-help-tip--text font-normal;
      @apply text-sm-phone leading-sm-phone;
      @screen tablet {
	@apply text-sm leading-sm;
      }
      @apply font-sans;

      &:first-child {
	@apply mt-0;
      }
    }

    /deep/ a {
      @apply text-current;
      /* TODO: Hover effect? */
    }
  }
}
</style>
