var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade-fast" }, on: { "after-leave": _vm.selfDestruct } },
    [
      _vm.isVisible
        ? _c(
            "div",
            {
              staticClass: "tip",
              class: {
                "opens-downward": !_vm.opensUpward,
                "opens-leftward": _vm.opensLeftward,
                "opens-rightward": !_vm.opensLeftward,
                "opens-upward": _vm.opensUpward,
                "spur-is-at-center-of-opener": !_vm.spurIsAtEdgeOfOpener,
                "spur-is-at-edge-of-opener": _vm.spurIsAtEdgeOfOpener,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c("div", {
                staticClass: "paragraphed-string",
                domProps: { innerHTML: _vm._s(_vm.paragraphedString) },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }