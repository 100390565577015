<!-- This component is meant to be invoked by application components. -->

<template>
  <div class="form-button form-submit" :class="{disabled: isDisabled, 'labels-at-left': labelsAreAtLeft, 'labels-on-top': labelsAreOnTop}">
    <button :class="classes" type="submit" v-html="label"/>
  </div>
</template>

<script>
import formButton from "./formButton";

export default {name: "FormSubmit", mixins: [formButton]};
</script>

<style lang="scss" scoped>
@import "./formButton";
</style>
