// This file is meant to be mixed into application components. It's idempotent. [Unneeded features have been deleted.]

export default {
  created() { this.forms = {blur: this.f_blur, focus: this.f_focus}; },
  methods: {
    f_blur(ref) { this.$refs[ref].blur(); },
    f_focus(ref) {
      //v Delay is needed, at least sometimes, for reasons unclear.
      setTimeout(() => { this.$refs[ref].focus(); });
    }
  }
};
