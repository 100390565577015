<!-- This component is meant to be invoked by formInputs; see ./README. [Unneeded features have been deleted.] -->

<!--v Should match assumptions of ValidationState and the method fV_validate defined in ./formValidation.js. -->
<template>
  <transition name="fade-fast">
    <div
      v-if="validationState && validationState.isEngaged && !validationState.isValid"
      class="form-error-tip"
      :class="{'left-aligned': !isRightAligned, 'right-aligned': isRightAligned}"
    >
      <span
	v-if="validationState.failsPresent"
	key="fails-present"
	v-html="'This is required.'"
      />
      <span
	v-else-if="validationState.failsEmailAddress"
	key="fails-email-address"
	v-html="'This should be an email address.'"
      />
      <span
	v-else-if="validationState.failsLengthAtLeast"
	key="fails-length-at-least"
	v-html="`This should be at least ${validationState.lengthAtLeast} characters long.`"
      />
      <span
	v-else-if="validationState.failsLengthAtMost"
	key="fails-length-at-most"
	v-html="`This should be at most ${validationState.lengthAtMost} characters long.`"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: "FormErrorTip",
  props: {
    isRightAligned:  {type: Boolean, required: false},
    validationState: {type: Object,  required: false}
  }
};
</script>

<style lang="scss" scoped>
/*v If webpack were well designed, this directive wouldn't be needed. (Automatically loading a file into every Sass "partial" is supposedly possible but  */
/*v ridiculously complicated, all the more so with Webpacker in the process. Moreover, the details allegedly changed between versions 4 and 5 of webpack; */
/*v see                                                                                                                                                   */
/*v                                                                                                                                                       */
/*v https://stackoverflow.com/a/65068051 .)                                                                                                               */
@import "../../../stylesheets/utilities";

.form-error-tip {
  @apply absolute;
  /*v In ancestral stacking context. Below Tip on FormLabel. */
  @apply z-10;
  @apply drop-shadows w-max;
  /*v TODO: Dubious. */
  max-width: 18.75rem; /* 300px */
  @screen tablet {
    /*v TODO: Dubious. */
    max-width: 25rem; /* 400px */
  }
  @apply bg-form-error-tip--background;
  padding: 0.46875rem 0.4375rem; /* 7.5px 7px */
  @screen tablet {
    padding: 0.625rem 0.5625rem; /* 10px 9px */
  }
  @apply text-form-error-tip--text;
  @apply text-sm-phone;
  @screen tablet {
    @apply text-sm;
  }
  @apply leading-none font-serif;

  &::before {
    content: "";
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
    @apply absolute bottom-full;
    /*v The height is sqrt(3)/2 times the width, so the clip-path forms an equilateral triangle. */
    height: 0.405949rem; /* 6.49519px */
    width: 0.46875rem; /* 7.5px */
    @screen tablet {
      height: 0.541266rem; /* 8.66025px */
      width: 0.625rem; /* 10px */
    }
    @apply bg-form-error-tip--background;
    /*v Ugh. Firefox interprets clip-path differently from the other major browsers, leaving a gap between the triangle and the box. */
    @include if-firefox {
      clip-path: polygon(0 100%, 0 calc(100% - 1px), 50% 0, 100% calc(100% - 1px), 100% 100%);
      bottom: calc(100% - 1px);
      height: 0.468449rem; /* (1+6.49519)px */
      @screen tablet {
	height: 0.603766rem; /* (1+8.66025)px */
      }
    }
  }

  /*v This pseudo-element is for situations where the tip must be scrolled into view at the bottom of a container. It creates what amounts to a bottom margin */
  /*v on the tip, so there's a little space between the bottom of the tip and the bottom of the container. (Because the tip is absolute-positioned, setting a */
  /*v margin-bottom on it doesn't have this effect.)                                                                                                          */
  &::after {
    /*v CSS equivalent of HTML &nbsp;. */
    content: "\00A0";
    @apply absolute top-full;
    @apply text-base-phone;
    @screen tablet {
      @apply text-base;
    }
    @apply leading-none;
  }

  &.left-aligned {
    @apply left-0;
    margin: 0.125rem 0 0 0.1875rem; /* 2px 0 0 3px */
    @screen tablet {
      margin: 0.1875rem 0 0 0.25rem; /* 3px 0 0 4px */
    }
    border-radius: 0 0.1875rem 0.1875rem 0.1875rem; /* 0 3px 3px 3px */

    &::before {
      @apply left-0;
    }
  }

  &.right-aligned {
    @apply right-0;
    margin: 0.125rem 0.1875rem 0 0; /* 2px 3px 0 0 */
    @screen tablet {
      margin: 0.1875rem 0.25rem 0 0; /* 3px 4px 0 0 */
    }
    border-radius: 0.1875rem 0 0.1875rem 0.1875rem; /* 3px 0 3px 3px */

    &::before {
      @apply right-0;
    }
  }
}
</style>
