var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invite-content" }, [
    _c("h1", [_vm._v("Invite")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validateAndInvite.apply(null, arguments)
          },
        },
      },
      [
        _c("FormTextField", {
          ref: "name-field",
          attrs: {
            "is-trimmed": "",
            label: "Name",
            "should-be-present": "",
            "validation-state": _vm.validationGroup.name,
          },
          on: {
            return: function ($event) {
              return _vm.forms.focus("email-address-field")
            },
          },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v
            },
            expression: "name",
          },
        }),
        _vm._v(" "),
        _c("FormTextField", {
          ref: "email-address-field",
          attrs: {
            "input-mode": "email",
            "is-lower-case": "",
            "is-trimmed": "",
            label: "Email address",
            note: "Lowercase only",
            "should-be-present": "",
            "validation-state": _vm.validationGroup.emailAddress,
          },
          on: {
            return: function ($event) {
              return _vm.forms.focus("note-area")
            },
          },
          model: {
            value: _vm.emailAddress,
            callback: function ($$v) {
              _vm.emailAddress = $$v
            },
            expression: "emailAddress",
          },
        }),
        _vm._v(" "),
        _c("FormTextArea", {
          ref: "note-area",
          attrs: { "is-trimmed": "", label: "Note", note: "Optional", rows: 6 },
          model: {
            value: _vm.note,
            callback: function ($$v) {
              _vm.note = $$v
            },
            expression: "note",
          },
        }),
        _vm._v(" "),
        _c("FormSubmit", {
          ref: "invite-button",
          attrs: {
            "is-disabled":
              _vm.validationGroup.isEngaged && !_vm.validationGroup.isValid,
            label: "Invite",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }