var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-button form-submit",
      class: {
        disabled: _vm.isDisabled,
        "labels-at-left": _vm.labelsAreAtLeft,
        "labels-on-top": _vm.labelsAreOnTop,
      },
    },
    [
      _c("button", {
        class: _vm.classes,
        attrs: { type: "submit" },
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }