// This module isn't specific to this application.
//
// This module supports dialogs and selects; see ../shared/dialogs/DialogMaster.vue and ../shared/forms/formSelect.js [deleted]. It enables instances of such
// components to disable scrolling of potentially scrollable elements in the background; see ../../packs/application.js.

export default {
  namespaced: true,
  //v Reactivity isn't needed, so mutations can operate on this state using simple assignment rather than Vue.set; see
  //v
  //v https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats .
  state: {els: new Set()},
  mutations: {
    ADD_FREEZE_REQUEST(state, component) {
      const uid = component._uid.toString();
      for (let el of state.els) {
	if (el.$_scrollableElements_freezeRequests.size === 0) freezeElement(el);
	el.$_scrollableElements_freezeRequests.add(uid);
      }
    },
    DELETE_FREEZE_REQUEST(state, component) {
      const uid = component._uid.toString();
      for (let el of state.els) {
	const deleted = el.$_scrollableElements_freezeRequests.delete(uid);
	if (deleted && el.$_scrollableElements_freezeRequests.size === 0) thawElement(el);
      }
    },
    ADD_SCROLLABLE_ELEMENT(state, el) {
      el.$_scrollableElements_freezeRequests = new Set();
      state.els.add(el);
    },
    DELETE_SCROLLABLE_ELEMENT(state, el) {
      state.els.delete(el);
      //v Paranoia.
      if (el.$_scrollableElements_freezeRequests.size > 0) thawElement(el);
      delete el.$_scrollableElements_freezeRequests;
    }
  },
  actions: {
    addFreezeRequest(context, component) { context.commit("ADD_FREEZE_REQUEST", component); },
    deleteFreezeRequest(context, component) { context.commit("DELETE_FREEZE_REQUEST", component); },
    addScrollableElement(context, el) { context.commit("ADD_SCROLLABLE_ELEMENT", el); },
    deleteScrollableElement(context, el) { context.commit("DELETE_SCROLLABLE_ELEMENT", el); }
  }
};

function freezeElement(el) {
  let freezingStyles;
  if (utilities.hasClass("has-child-with-mx-auto", el)) {
    let
      oldMarginRightString = el.style.marginRight,
      matchData = oldMarginRightString.match(/calc\((.+)\)/),
      newMarginRightString = `${utilities.scrollbarWidth(el)}px`;
    if (matchData) oldMarginRightString = matchData[1];
    if (oldMarginRightString) newMarginRightString = `calc(${oldMarginRightString} + ${newMarginRightString})`;
    freezingStyles = {marginRight: newMarginRightString, overflowY: "hidden"};
  } else
    freezingStyles =
      //v The failure of browser makers to integrate scrollbars into the box model in a consistent, standard way is annoying.
      (window.browserIsFirefox || window.browserIsSafari || window.browserIsSafariWebappMode)
      ? {overflowY: "hidden"}
      : {overflowY: "hidden", width: getComputedStyle(el).width};
  el.$_scrollableElements_savedStyles = utilities.pick(el.style, utilities.keys(freezingStyles));
  utilities.assign(el.style, freezingStyles);
};

function thawElement(el) {
  utilities.assign(el.style, el.$_scrollableElements_savedStyles);
  delete el.$_scrollableElements_savedStyles;
};
