// This file is meant to be mixed into formItems; see ./README.

import FormLabel from "./FormLabel";

export default {
  components: {FormLabel},
  props: {
    //v Given a reactive datum auxiliaryIf, one might reasonably expect
    //v
    //v <SomeFormItem ...>
    //v   <template v-if="auxiliaryIf" #auxiliary-content>
    //v     ...
    //v   </template>
    //v </SomeFormItem>
    //v
    //v to display the auxiliary when auxiliaryIf is truthy and not when auxiliaryIf is falsey. Alas, one would be mistaken. Initially, the auxiliary is indeed
    //v displayed or not according to auxiliaryIf. Moreover, if auxiliaryIf first is truthy, hence the auxiliary is displayed, and auxiliaryIf later becomes
    //v falsey, then the auxiliary gets removed. Subsequently, however, the auxiliary doesn't respond to changes in auxiliaryIf, for reasons unclear.
    //v
    //v Accordingly, auxiliaryIf must be passed as a prop, so that
    //v
    //v <SomeFormItem :auxiliary-if="auxiliaryIf" ...>
    //v   <template #auxiliary-content>
    //v     ...
    //v   </template>
    //v </SomeFormItem>
    //v
    //v has the desired effect.
    auxiliaryIf:    {type: Boolean, required: false, default: true},
    helpString:     {type: String,  required: false               },
    label:          {type: String,  required: true                },
    labelsAreOnTop: {type: Boolean, required: false               },
    //v May contain HTML specifying inline elements but shouldn't contain HTML specifying block elements. Any user-supplied content should be HTML-escaped, to
    //v thwart injection attacks.
    note:           {type: String,  required: false               }
    // Every formItem has a value prop, so it may seem strange that the value prop isn't declared here. The reason for this is that different formItems take
    // values of different types; for example, FormCheckbox takes Boolean values, FormMultiselect takes array values, etc.
  },
  computed: {
    auxiliaryContentSlotIsFilled() { return !!this.$slots["auxiliary-content"] && !!this.$slots["auxiliary-content"][0]; },
    auxiliaryIsPresent() { return this.auxiliaryIf && this.auxiliaryContentSlotIsFilled; },
    labelsAreAtLeft() { return !this.labelsAreOnTop; }
  }
};
