// This file is meant to be mixed into application components that invoke dialogs. It's idempotent.

import DialogComponent from "./DialogComponent";

export default {
  created() {
    this.dialogs = {
      ajaxError: this.d_ajaxError,
      dialog: this.d_dialog,
      error: this.d_error,
      info: this.d_info,
      question: this.d_question,
      warning: this.d_warning
    };
  },
  methods: {
    d_ajaxError(error, options) {
      //^ error should be an error object from Axios or similar.
      if (error?.response?.data?.message)
	return this.d_error(error.response.data.message);
      else if (error?.response?.data?.noMessage)
	return Promise.resolve();
      return this.d_error("Oops! Please try again.");
    },
    d_dialog(content, kind, options = {}) {
      //^ content should be a string, an array of two strings, or an object specifying a Vue component instance. In the first two cases, the string or strings
      //^ may contain HTML specifying inline elements but shouldn't contain HTML specifying block elements, and any user-supplied content within them should be
      //^ HTML-escaped, to thwart injection attacks. In the last case, the object should have properties options and propsData with values the options object of
      //^ the component and the props-data object for the instance, respectively.
      //^
      //^ kind should be "alert" or "confirm".
      //^
      //^ Valid options include beforeClose, heading, height, iconKind, isWide, noButtonLabel, okButtonLabel, rightMarginShouldExcludeScrollbar, and
      //^ yesButtonLabel.
      //^
      //^ If given, options.beforeClose should be of the form function(dialogClosureSignal, resolveDialogPromise, contentComponent), where dialogClosureSignal
      //^ is true if kind is "alert" and the OK button is clicked, if kind is "confirm" and the Yes button is clicked, or if Return is pressed and propagation
      //^ isn't stopped within the content component (if any), false if kind is "confirm" and the No button is clicked, or null if the close icon is clicked or
      //^ if Escape is pressed and propagation isn't stopped within the content component (if any); resolveDialogPromise is the resolver of the promise below;
      //^ and contentComponent is the content component (if any). If options.beforeClose returns falsey, closing is aborted.
      //^
      //^ If given, options.heading, options.noButtonLabel, options.okButtonLabel, and options.yesButtonLabel should be HTML-sanitized.
      //^
      //^ If given, options.height should be a CSS length (e.g., "90vh"), which will apply only when the dialog isn't fullscreen.
      //^
      //^ If given, options.iconKind should be "error", "info", "question", or "warning".
      //^
      //^ options.noButtonLabel and yesButtonLabel are applicable if kind is "confirm", and options.okButtonLabel is applicable if kind is "alert".
      options = utilities.defaults(
	options,
	{
	  beforeClose(dialogClosureSignal, resolveDialogPromise) {
	    resolveDialogPromise(dialogClosureSignal);
	    return true;
	  },
	  noButtonLabel: "No",
	  okButtonLabel: "OK",
	  yesButtonLabel: "Yes"
	}
      );
      let
	contentMainString,
	contentNoteString,
	contentComponentContainsFormField,
	contentComponentOptions,
	contentComponentPropsData;
      if (typeof content === "string")
	contentMainString = content;
      else if (content instanceof Array) {
	contentMainString = content[0];
	contentNoteString = content[1];
      } else {
	contentComponentContainsFormField = content.containsFormField;
	contentComponentOptions = content.options;
	contentComponentPropsData = content.propsData;
      }
      return new Promise(
	(resolve) => {
	  const
	    DialogComponentConstructor = Vue.extend(DialogComponent),
	    dialog = new DialogComponentConstructor(
	      {
		parent: this,
		propsData: {
		  beforeClose: options.beforeClose,
		  contentComponentContainsFormField,
		  contentComponentOptions,
		  contentComponentPropsData,
		  contentMainString,
		  contentNoteString,
		  heading: options.heading,
		  height: options.height,
		  iconKind: options.iconKind,
		  isWide: options.isWide,
		  kind,
		  noButtonLabel: options.noButtonLabel,
		  okButtonLabel: options.okButtonLabel,
		  resolve,
		  rightMarginShouldExcludeScrollbar: options.rightMarginShouldExcludeScrollbar,
		  yesButtonLabel: options.yesButtonLabel
		}
	      }
	    );
	  dialog.$mount();
	  document.body.appendChild(dialog.$el);
	}
      );
    },
    d_error(content, options) { return this.d_dialog(content, "alert", utilities.defaults({iconKind: "error"}, options)); },
    d_info(content, options) { return this.d_dialog(content, "alert", utilities.defaults({iconKind: "info"}, options)); },
    d_question(content, options) { return this.d_dialog(content, "confirm", utilities.defaults({iconKind: "question"}, options)); },
    d_warning(content, options) { return this.d_dialog(content, "confirm", utilities.defaults({iconKind: "warning"}, options)); }
  }
};
