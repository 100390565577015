// This class is meant to be invoked by application components; see ./README.

import ValidationState from "./ValidationState";

export default class {
  constructor(keys) {
    //^ keys should be an array of strings, not including "disengage", "engage", "isEngaged", "isValid", "keys", or "validationStates".
    this.isEngaged = false;
    this.keys = keys;
    this.validationStates = utilities.fromPairs(keys.map(function(key) { return [key, new ValidationState()]; }));
    utilities.forEach(keys, (key) => { this[key] = this.validationStates[key]; });
  }
  get isValid() {
    let isValid = true;
    utilities.forEach(
      this.keys,
      (key) => {
	if (!this.validationStates[key].isValid) {
	  isValid = false;
	  return false;
	}
      }
    );
    return isValid;
  }
  disengage() {
    utilities.forEach(this.keys, (key) => { this.validationStates[key].disengage(); });
    this.isEngaged = false;
  }
  engage() {
    utilities.forEach(this.keys, (key) => { this.validationStates[key].engage(); });
    this.isEngaged = true;
  }
};
