<!-- This component is meant to be invoked by application components. -->

<template>
  <span class="help-tip-opener" @click="openHelpTip">
    <!--^ Once the tip is open, it intercepts any subsequent click on the opener and closes itself. -->
    <span class="string-and-help-nubbin">
      <span v-html="string"/>&nbsp;<span class="help-nubbin-help" :class="size"/>
    </span>
  </span>
</template>

<script>
import tips from "../tips/tips";

export default {
  name: "HelpTipOpener",
  mixins: [tips],
  props: {
    //v May contain HTML specifying inline elements but shouldn't contain HTML specifying block elements. Any user-supplied content should be HTML-escaped, to
    //v thwart injection attacks.
    helpTipString: {type: String, required: true},
    //v Should be "md".
    size:          {type: String, required: true},
    //v May contain HTML specifying inline elements but shouldn't contain HTML specifying block elements. Any user-supplied content should be HTML-escaped, to
    //v thwart injection attacks.
    string:        {type: String, required: true}
  },
  methods: {openHelpTip() { this.tips.tip(this.$el, this.helpTipString, {spurIsAtEdgeOfOpener: true}); }}
}
</script>

<style lang="scss" scoped>
.help-tip-opener {
  .string-and-help-nubbin {
    @apply cursor-pointer;

    .help-nubbin-help {
      &.md {
        @apply text-md;
      }

      &:before {
        @apply text-help-nubbin--background;
      }
    }

    @media (hover: hover) {
      &:hover {
	.help-nubbin-help:before {
	  @apply text-help-nubbin--background-hover;
	}
      }
    }
  }

  &.active {
    .help-nubbin-help:before {
      @apply text-help-nubbin--background-active;
    }
  }
}
</style>
