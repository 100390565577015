<!-- See app/views/administrators/invite.html.erb. -->

<template>
  <div class="invite-content">
    <h1>Invite</h1>
    <form @submit.prevent="validateAndInvite">
      <FormTextField
	ref="name-field"
	is-trimmed
	label="Name"
	should-be-present
	:validation-state="validationGroup.name"
	v-model="name"
	@return="forms.focus('email-address-field')"
      />
      <FormTextField
	ref="email-address-field"
	input-mode="email"
	is-lower-case
	is-trimmed
	label="Email address"
	note="Lowercase only"
	should-be-present
	:validation-state="validationGroup.emailAddress"
	v-model="emailAddress"
	@return="forms.focus('note-area')"
      />
      <FormTextArea
	ref="note-area"
	is-trimmed
	label="Note"
	note="Optional"
	:rows="6"
	v-model="note"
      />
      <FormSubmit
	ref="invite-button"
	:is-disabled="validationGroup.isEngaged && !validationGroup.isValid"
	label="Invite"
      />
    </form>
  </div>
</template>

<script>
import FormSubmit from "../shared/forms/FormSubmit";
import FormTextArea from "../shared/forms/FormTextArea";
import FormTextField from "../shared/forms/FormTextField";

import dialogs from "../shared/dialogs/dialogs";

import forms from "../shared/forms/forms";

import ValidationGroup from "../shared/forms/ValidationGroup";

export default {
  name: "InviteContent",
  components: {FormSubmit, FormTextArea, FormTextField},
  mixins: [dialogs, forms],
  data() { return {emailAddress: null, name: null, note: null, validationGroup: new ValidationGroup(["emailAddress", "name"])}; },
  mounted() {
    this.forms.focus("name-field");
    //v Convenient for debugging in a browser console.
    window.inviteContent = this;
  },
  beforeDestroy() { delete window.inviteContent; },
  methods: {
    validateAndInvite() {
      this.validationGroup.engage();
      if (!this.validationGroup.isValid) return;
      Axios.post(paths.INVITE_FOR_ADMINISTRATOR, utilities.ajaxParams({emailAddress: this.emailAddress, name: this.name}))
	.then(() => { Turbolinks.visit(paths.INVITE); })
	.catch((error) => { this.dialogs.ajaxError(error); });
    }
  }
};
</script>
