// This file is meant to be mixed into formInputs; see ./README.

import FormErrorTip from "./FormErrorTip";

import formItem from "./formItem";
import formValidation from "./formValidation";

export default {
  components: {FormErrorTip},
  mixins: [formItem, formValidation],
  model: {event: "touch"},
  props: {
    isDisabled: {type: Boolean, required: false}
  },
  created() { this.uid = this._uid.toString(); }
};
