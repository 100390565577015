// This file is meant to be mixed into application components. It's idempotent.

import FlickerComponent from "vues/shared/flickers/FlickerComponent";

export default {
  created() { this.flickers = {flicker: this.f_flicker}; },
  methods: {
    f_flicker(string, options = {}) {
      //^ string may contain HTML specifying inline elements but shouldn't contain HTML specifying block elements, and any user-supplied content within it
      //^ should be HTML-escaped, to thwart injection attacks.
      //^
      //^ Valid option is duration.
      //^
      //^ If given, options.duration should be in milliseconds.
      options = utilities.defaults(options, {duration: 3000});
      const
	FlickerComponentConstructor = Vue.extend(FlickerComponent),
	flicker = new FlickerComponentConstructor(
	  {
	    parent: this,
	    propsData: {duration: options.duration, string}
	  }
	);
      flicker.$mount();
      document.body.appendChild(flicker.$el);
    }
  }
};
