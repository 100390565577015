var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-fast" } }, [
    _vm.validationState &&
    _vm.validationState.isEngaged &&
    !_vm.validationState.isValid
      ? _c(
          "div",
          {
            staticClass: "form-error-tip",
            class: {
              "left-aligned": !_vm.isRightAligned,
              "right-aligned": _vm.isRightAligned,
            },
          },
          [
            _vm.validationState.failsPresent
              ? _c("span", {
                  key: "fails-present",
                  domProps: { innerHTML: _vm._s("This is required.") },
                })
              : _vm.validationState.failsEmailAddress
              ? _c("span", {
                  key: "fails-email-address",
                  domProps: {
                    innerHTML: _vm._s("This should be an email address."),
                  },
                })
              : _vm.validationState.failsLengthAtLeast
              ? _c("span", {
                  key: "fails-length-at-least",
                  domProps: {
                    innerHTML: _vm._s(
                      "This should be at least " +
                        _vm.validationState.lengthAtLeast +
                        " characters long."
                    ),
                  },
                })
              : _vm.validationState.failsLengthAtMost
              ? _c("span", {
                  key: "fails-length-at-most",
                  domProps: {
                    innerHTML: _vm._s(
                      "This should be at most " +
                        _vm.validationState.lengthAtMost +
                        " characters long."
                    ),
                  },
                })
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }