var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        name:
          _vm.isWide && _vm.contentComponentContainsFormField
            ? "fade-fast-unless-phone"
            : "fade-fast",
      },
      on: {
        "after-enter": _vm.addBodyWrapperToScrollableElements,
        "after-leave": _vm.selfDestruct,
        "before-leave": _vm.deleteBodyWrapperFromScrollableElements,
      },
    },
    [
      _vm.isVisible
        ? _c("div", { staticClass: "dialog" }, [
            _c("div", {
              staticClass: "scrim",
              class: {
                fullscreen: _vm.isFullscreen,
                "not-fullscreen": !_vm.isFullscreen,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "box",
                staticClass: "box",
                class: {
                  fullscreen: _vm.isFullscreen,
                  "height-specified": !!_vm.height,
                  wide: _vm.isWide,
                },
              },
              [
                _vm.heading
                  ? _c("div", { staticClass: "header" }, [
                      _c("div", {
                        staticClass: "heading",
                        domProps: { innerHTML: _vm._s(_vm.heading) },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close-button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.close(null)
                            },
                          },
                        },
                        [
                          _c("div", {
                            staticClass: "icon",
                            domProps: {
                              innerHTML: _vm._s(_vm.svgs.miscellaneous.X),
                            },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "body-wrapper",
                    staticClass: "body-wrapper",
                    class: { "no-header": !_vm.heading },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "body",
                        class: {
                          "right-margin-should-exclude-scrollbar":
                            _vm.rightMarginShouldExcludeScrollbar,
                        },
                      },
                      [
                        _vm.iconKind
                          ? _c("div", { staticClass: "icon-container" }, [
                              _vm.iconKind === "error"
                                ? _c("div", {
                                    key: "error",
                                    staticClass: "icon",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.svgs.dialogIcons.ERROR
                                      ),
                                    },
                                  })
                                : _vm.iconKind === "info"
                                ? _c("div", {
                                    key: "info",
                                    staticClass: "icon",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.svgs.dialogIcons.INFO
                                      ),
                                    },
                                  })
                                : _vm.iconKind === "question"
                                ? _c("div", {
                                    key: "question",
                                    staticClass: "icon",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.svgs.dialogIcons.QUESTION
                                      ),
                                    },
                                  })
                                : _c("div", {
                                    key: "warning",
                                    staticClass: "icon",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.svgs.dialogIcons.WARNING
                                      ),
                                    },
                                  }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contentMainString
                          ? _c("div", {
                              staticClass: "content-string-container",
                              domProps: {
                                innerHTML: _vm._s(_vm.paragraphedContentString),
                              },
                            })
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "content-component-instance-container",
                              },
                              [
                                _c(
                                  _vm.contentComponentOptions,
                                  _vm._b(
                                    {
                                      ref: "content-component-instance",
                                      tag: "component",
                                      on: {
                                        close: _vm.close,
                                        "enable-or-disable-go-button":
                                          _vm.enableOrDisableGoButton,
                                      },
                                    },
                                    "component",
                                    _vm.contentComponentPropsData,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.kind === "alert"
                  ? _c("div", { key: "alert", staticClass: "footer" }, [
                      _c("button", {
                        ref: "go-button",
                        staticClass: "go-button ok-button",
                        attrs: { type: "button" },
                        domProps: { innerHTML: _vm._s(_vm.okButtonLabel) },
                        on: {
                          click: function ($event) {
                            return _vm.close(true)
                          },
                        },
                      }),
                    ])
                  : _c("div", { key: "confirm", staticClass: "footer" }, [
                      _c("button", {
                        ref: "go-button",
                        staticClass: "go-button yes-button",
                        attrs: { type: "button" },
                        domProps: { innerHTML: _vm._s(_vm.yesButtonLabel) },
                        on: {
                          click: function ($event) {
                            return _vm.close(true)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("button", {
                        staticClass: "stop-button no-button",
                        attrs: { type: "button" },
                        domProps: { innerHTML: _vm._s(_vm.noButtonLabel) },
                        on: {
                          click: function ($event) {
                            return _vm.close(false)
                          },
                        },
                      }),
                    ]),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }