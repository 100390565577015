<!-- This component is meant to be invoked by the flickers mixin. -->

<template>
  <transition name="fade-fast" @after-enter="queueHide" @after-leave="selfDestruct">
    <div v-if="isVisible" class="flicker">
      <span v-html="string"/>
    </div>
  </transition>
</template>

<script>
export default {
  //v FlickerComponent rather than Flicker, because single-word component names can be problematic.
  name: "FlickerComponent",
  props: {
    duration: {type: Number, required: true},
    string:   {type: String, required: true}
  },
  data() { return {isVisible: false}; },
  mounted() { this.isVisible = true; },
  methods: {
    queueHide() {
      setTimeout(() => { this.isVisible = false; }, this.duration);
    },
    selfDestruct() {
      this.$destroy();
      this.$el.parentElement.removeChild(this.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
.flicker {
  @apply fixed top-1/2 left-1/2;
  transform: translate(-50%, -50%);
  /*v In document stacking context. Above all (except subsequent dialogs or flickers). */
  @apply z-50;
  @apply box-shadows;
  border-radius: 0.1875rem; /* 3px */
  @apply bg-flicker--background;
  padding: 0.5625rem 0.53125rem; /* 9px 8.5px */
  @screen tablet {
    padding: 0.75rem 0.6875rem; /* 12px 11px */
  }
  @apply text-flicker--text;
  @apply text-base-phone;
  @screen tablet {
    @apply text-base;
  }
  @apply leading-none font-serif;
}
</style>
