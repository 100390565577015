var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "form-item form-input form-visorable form-field form-text-area",
      class: {
        disabled: _vm.isDisabled,
        "labels-at-left": _vm.labelsAreAtLeft,
        "labels-on-top": _vm.labelsAreOnTop,
        "with-visor": _vm.visorIsPresent,
      },
    },
    [
      _c(
        "div",
        { staticClass: "row-1" },
        [
          _vm.labelsAreOnTop
            ? _c("FormLabel", {
                attrs: {
                  "help-string": _vm.helpString,
                  "is-disabled": _vm.isDisabled,
                  label: _vm.label,
                  "labels-are-on-top": "",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.note
            ? _c("div", { staticClass: "note-container" }, [
                _c("span", {
                  staticClass: "note",
                  domProps: { innerHTML: _vm._s(_vm.note) },
                }),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row-2" }, [
        _c(
          "div",
          { staticClass: "column-1" },
          [
            _vm.labelsAreAtLeft
              ? _c("FormLabel", {
                  attrs: {
                    "help-string": _vm.helpString,
                    "is-disabled": _vm.isDisabled,
                    label: _vm.label,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "column-2" },
          [
            _c(
              "textarea",
              {
                ref: "input",
                attrs: {
                  placeholder: _vm.placeholder,
                  rows: _vm.rows,
                  spellcheck: _vm.isSpellChecked,
                },
                on: {
                  blur: _vm.onBlur,
                  input: _vm.onInput,
                  keydown: _vm.containReturn,
                },
              },
              [_vm._v(_vm._s(_vm.value))]
            ),
            _vm._v(" "),
            _c("FormErrorTip", {
              attrs: { "validation-state": _vm.validationState },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "column-3" }, [
          _vm.labelsAreAtLeft && _vm.auxiliaryIsPresent
            ? _c(
                "div",
                { staticClass: "auxiliary" },
                [_vm._t("auxiliary-content")],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row-3" },
        [
          _c("ShadeTopTransition", { attrs: { mode: "out-in" } }, [
            _vm.visorIsPresent
              ? _c(
                  "div",
                  { staticClass: "visor" },
                  [_vm._t("visor-content")],
                  2
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row-4" }, [
        _vm.labelsAreOnTop && _vm.auxiliaryIsPresent
          ? _c(
              "div",
              { staticClass: "auxiliary" },
              [_vm._t("auxiliary-content")],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }