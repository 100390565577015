<!-- This component is meant to be invoked by application components. -->

<template>
  <div
    class="form-item form-input form-visorable form-field form-text-field"
    :class="{disabled: isDisabled, 'labels-at-left': labelsAreAtLeft, 'labels-on-top': labelsAreOnTop, 'with-visor': visorIsPresent}"
  >
    <div class="row-1">
      <FormLabel v-if="labelsAreOnTop" :help-string="helpString" :is-disabled="isDisabled" :label="label" labels-are-on-top/>
      <div v-if="note" class="note-container">
	<span class="note" v-html="note"/>
      </div>
      <!-- This component has no modifiers. -->
    </div>
    <div class="row-2">
      <div class="column-1">
	<FormLabel v-if="labelsAreAtLeft" :help-string="helpString" :is-disabled="isDisabled" :label="label"/>
      </div>
      <div class="column-2">
	<input
	  ref="input"
	  :class="{'lower-case': isLowerCase}"
	  :autocomplete="autocomplete"
	  :inputmode="inputMode"
	  :placeholder="placeholder"
	  :spellcheck="isSpellChecked"
	  type="text"
	  :value="value"
	  @blur="onBlur"
	  @input="onInput"
	  @keydown="signalReturn"
	>
	<FormErrorTip :validation-state="validationState"/>
      </div>
      <div class="column-3">
	<div v-if="labelsAreAtLeft && auxiliaryIsPresent" class="auxiliary">
	  <slot name="auxiliary-content"/>
	</div>
      </div>
    </div>
    <div class="row-3">
      <ShadeTopTransition mode="out-in">
	<div v-if="visorIsPresent" class="visor">
	  <slot name="visor-content"/>
	</div>
      </ShadeTopTransition>
    </div>
    <div class="row-4">
      <div v-if="labelsAreOnTop && auxiliaryIsPresent" class="auxiliary">
	<slot name="auxiliary-content"/>
      </div>
    </div>
  </div>
</template>

<script>
import formField from "./formField";

export default {
  name: "FormTextField",
  mixins: [formField],
  props: {
    isLowerCase: {type: Boolean, required: false}
  },
  methods: {
    lowerCaseIfNeeded() { if (this.isLowerCase) this.inputEl.value = this.inputEl.value.toLowerCase(); },
    onBlur() {
      this.rightTrimIfNeeded();
      this.touch();
    },
    onInput() {
      this.normalizeIfNeeded();
      this.leftTrimIfNeeded();
      this.lowerCaseIfNeeded();
      this.filterByMaskOrInputModeIfNeeded();
      this.touch();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./formField";

.form-text-field {
  input.lower-case {
    text-transform: lowercase;
  }
}
</style>
