var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade-fast" },
      on: { "after-enter": _vm.queueHide, "after-leave": _vm.selfDestruct },
    },
    [
      _vm.isVisible
        ? _c("div", { staticClass: "flicker" }, [
            _c("span", { domProps: { innerHTML: _vm._s(_vm.string) } }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }