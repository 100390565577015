var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-label",
      class: {
        disabled: _vm.isDisabled,
        "labels-at-left": _vm.labelsAreAtLeft,
        "labels-on-top": _vm.labelsAreOnTop,
      },
    },
    [
      _vm.helpTipIsPresent
        ? _c(
            "div",
            { key: "help-tip-is-present", staticClass: "label" },
            [
              _c("HelpTipOpener", {
                attrs: {
                  "help-string": _vm.helpString,
                  size: "md",
                  string: _vm.label,
                },
              }),
            ],
            1
          )
        : _vm.label !== ""
        ? _c("div", {
            key: "help-tip-is-not-present",
            staticClass: "label",
            domProps: { innerHTML: _vm._s(_vm.label) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }