<!-- This component is meant to be invoked by formItems; see ./README. -->

<template>
  <div class="form-label" :class="{disabled: isDisabled, 'labels-at-left': labelsAreAtLeft, 'labels-on-top': labelsAreOnTop}">
    <div v-if="helpTipIsPresent" key="help-tip-is-present" class="label">
      <HelpTipOpener :help-string="helpString" size="md" :string="label"/>
    </div>
    <div v-else-if="label !== ''" key="help-tip-is-not-present" class="label" v-html="label"/>
  </div>
</template>

<script>
import HelpTipOpener from "../openers/HelpTipOpener";

export default {
  name: "FormLabel",
  components: {HelpTipOpener},
  props: {
    //v May contain HTML specifying inline elements but shouldn't contain HTML specifying block elements. Any user-supplied content should be HTML-escaped, to
    //v thwart injection attacks. Assumed constant.
    helpString:     {type: String,  required: false},
    isDisabled:     {type: Boolean, required: false},
    //v May contain HTML specifying inline elements but shouldn't contain HTML specifying block elements. Any user-supplied content should be HTML-escaped, to
    //v thwart injection attacks.
    label:          {type: String,  required: true },
    labelsAreOnTop: {type: Boolean, required: false}
  },
  computed: {labelsAreAtLeft() { return !this.labelsAreOnTop; }},
  created() {
    //v Prop helpString is assumed constant.
    this.helpTipIsPresent = !!this.helpString;
  }
};
</script>

<style lang="scss" scoped>
.form-label {
  &.labels-on-top {
    /*v In case there's both a note and modifiers, push the note to the right. */
    @apply flex-grow;
  }

  .label {
    @apply font-bold;
    @apply text-base-phone;
    @screen tablet {
      @apply text-base;
    }
    @apply font-sans-narrow;

    /deep/ .tip {
      /*v In ancestral stacking context. Above FormErrorTip. */
      @apply z-20;
    }
  }

  &.labels-at-left {
    .label {
      /*v Ordinarily, labels shouldn't wrap, but in case they do, allow a little leading. */
      @apply leading-tight;
    }
  }

  &.labels-on-top {
    .label {
      height: 1.125rem; /* 18px */
      padding-top: 0.09375rem; /* 1.5px */
      @screen tablet {
	height: 1.5rem; /* 24px */
	padding-top: 0.125rem; /* 2px */
      }
      @apply leading-none;
    }
  }

  &.disabled {
    @apply opacity-50 pointer-events-none;
  }
}
</style>
