var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "help-tip-opener", on: { click: _vm.openHelpTip } },
    [
      _c("span", { staticClass: "string-and-help-nubbin" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.string) } }),
        _vm._v(" "),
        _c("span", { staticClass: "help-nubbin-help", class: _vm.size }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }