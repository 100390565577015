// This file is meant to be mixed into formFields; see ./README. [Unneeded features have been deleted.]

import formVisorable from "./formVisorable";

export default {
  mixins: [formVisorable],
  props: {
    //v Relevant to FormPasswordField and FormTextField only, not FormTextArea. (In theory, autocomplete works on textareas, but in practice, browser support is
    //v sketchy.)
    autocomplete:   {type: String,  required: false},
    //v Relevant to FormTextField only, not FormPasswordField or FormTextArea. (Defined here because it can entail filtering, which is natural to implement
    //v together with filtering by mask; see filterByMaskOrInputModeIfNeeded below.) Assumed constant.
    inputMode:      {type: String,  required: false},
    //v Relevant to FormTextArea and FormTextField only, not FormPasswordField.
    isSpellChecked: {type: Boolean, required: false},
    isTrimmed:      {type: Boolean, required: false},
    //v Relevant to FormTextArea and FormTextField only, not FormPasswordField. If both inputMode and mask are given, then filtering by mask overrides filtering
    //v by inputMode. Assumed constant.
    mask:           {type: RegExp,  required: false},
    //v If given, should be "NFC", "NFD", "NFKC", or "NFKD".
    normalization:  {type: String,  required: false},
    placeholder:    {type: String,  required: false},
    value:          {type: String,  required: false}
  },
  created() {
    //v Props mask and inputMode are assumed constant.
    if (this.mask)
      this.fF_globalMask = new RegExp(this.mask, "g");
    else if (this.inputMode === "decimal")
      //v If inputmode is "decimal", then iOS 12.2 offers [\d\.], while Chrome for Android offers a superset; see
      //v
      //v https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/ .
      this.fF_globalMask = /[^\d\.]/g;
    else if (this.inputMode === "numeric")
      //v If inputmode is "numeric", then Chrome for Android offers [-\d\., ], while iOS 12.2 offers a superset; see
      //v
      //v https://css-tricks.com/everything-you-ever-wanted-to-know-about-inputmode/ .
      this.fF_globalMask = /[^-\d\., ]/g;
  },
  mounted() { this.inputEl = this.$refs.input; },
  methods: {
    blur() { this.inputEl.blur(); },
    //v Relevant to FormTextArea and FormTextField only, not FormPasswordField.
    filterByMaskOrInputModeIfNeeded() {
      if (this.fF_globalMask) {
	const
	  value = this.inputEl.value,
	  filteredValue = value.replace(this.fF_globalMask, "");
	if (filteredValue.length < value.length) {
	  //v The filtered characters should precede the cursor, whether this method is triggered by typing or pasting.
	  const newCursorPosition = this.inputEl.selectionStart-(value.length-filteredValue.length);
	  this.inputEl.value = filteredValue;
	  this.inputEl.setSelectionRange(newCursorPosition, newCursorPosition);
	}
      }
    },
    focus() { this.inputEl.focus(); },
    leftTrimIfNeeded() {
      if (this.isTrimmed) {
	const
	  value = this.inputEl.value,
	  leftTrimmedValue = value.trimStart();
	if (leftTrimmedValue.length < value.length) {
	  const
	    lengthDifference = value.length-leftTrimmedValue.length,
	    newSelectionStart = Math.max(this.inputEl.selectionStart-lengthDifference, 0),
	    newSelectionEnd = Math.max(this.inputEl.selectionEnd-lengthDifference, 0);
	  this.inputEl.value = leftTrimmedValue;
	  this.inputEl.setSelectionRange(newSelectionStart, newSelectionEnd);
	}
      }
    },
    normalizeIfNeeded() { if (this.normalization) this.inputEl.value = this.inputEl.value.normalize(this.normalization); },
    rightTrimIfNeeded() {
      if (this.isTrimmed) {
	const
	  value = this.inputEl.value,
	  rightTrimmedValue = value.trimEnd();
	if (rightTrimmedValue.length < value.length) {
	  const
	    newSelectionStart = Math.min(this.inputEl.selectionStart, rightTrimmedValue.length),
	    newSelectionEnd = Math.min(this.inputEl.selectionEnd, rightTrimmedValue.length);
	  this.inputEl.value = rightTrimmedValue;
	  this.inputEl.setSelectionRange(newSelectionStart, newSelectionEnd);
	}
      }
    },
    //v Relevant to FormPasswordField and FormTextField only, not FormTextArea.
    signalReturn(event) {
      if (event.key === "Enter") {
	this.blur();
	this.$emit("return");
	//v In case other, less specific enter handlers are lurking, such as the one defined in the mounted hook of the Dialog component.
	event.stopPropagation();
      }
    },
    touch() { this.$emit("touch", this.inputEl.value || null); }
  }
};
