<!-- See app/views/layouts/_page_entry_dialogs.html.erb. -->

<template/>

<script>
import dialogs from "../shared/dialogs/dialogs";

import flickers from "../shared/flickers/flickers";

export default {
  name: "PageEntryDialogs",
  mixins: [dialogs, flickers],
  props: {
    browserIsIe:    {type: Boolean,         required: false},
    errorContent:   {type: [Array, String], required: false},
    flickerContent: {type: String,          required: false},
    infoContent:    {type: [Array, String], required: false}
  },
  mounted() {
    if (!Turbolinks.currentVisitIsRestoration) {
      //^ Avoid redisplaying a dialog if the user revisits a page via the browser's back or forward button.
      if (this.flickerContent)
	this.flickers.flicker(this.flickerContent);
      else if (this.errorContent)
	this.dialogs.error(this.errorContent);
      else if (this.infoContent)
	this.dialogs.info(this.infoContent);
      else if (this.browserIsIe && localStorage.getItem("userWasWarnedAboutIe") !== "true") {
	//v IE has supported local storage since IE8, which was released in 2009; see
	//v
	//v https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage .
	localStorage.setItem("userWasWarnedAboutIe", "true");
	this.dialogs.error(
	  [
	    "You appear to be using Internet Explorer, which this site doesn’t support. If so, many features won’t work properly.",
	    [
	      "IE is obsolete and shouldn’t be used anymore. ",
	      "Since 2015, Microsoft has recommended switching to Edge.\n\n",
	      "If IE is set as your default browser, you can switch to Edge (or Chrome or Firefox, if they’re installed) via the instructions ",
	      `<a href="https://support.microsoft.com/en-us/help/4028606/windows-10-change-your-default-browser">here</a>`,
	      "."
	    ]
	      .join("")
	  ]
	);
      }
    }
  }
};
</script>
