var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "log-in-content" }, [
    _c("h1", [_vm._v("Log in")]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validateAndLogIn.apply(null, arguments)
          },
        },
      },
      [
        _c("FormTextField", {
          ref: "username-field",
          attrs: {
            "is-trimmed": "",
            label: "Username",
            "should-be-present": "",
            "validation-state": _vm.validationGroup.username,
          },
          on: {
            return: function ($event) {
              return _vm.forms.focus("password-field")
            },
          },
          model: {
            value: _vm.username,
            callback: function ($$v) {
              _vm.username = $$v
            },
            expression: "username",
          },
        }),
        _vm._v(" "),
        _c("FormPasswordField", {
          ref: "password-field",
          attrs: {
            "is-trimmed": "",
            label: "Password",
            "should-be-present": "",
            "validation-state": _vm.validationGroup.password,
          },
          on: { return: _vm.validateAndLogIn },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v
            },
            expression: "password",
          },
        }),
        _vm._v(" "),
        _c("FormSubmit", {
          ref: "log-in-button",
          attrs: {
            "is-disabled":
              _vm.validationGroup.isEngaged && !_vm.validationGroup.isValid,
            label: "Log in",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }