export const dialogIcons = {
  //v Derived from src/dialog_icons/circle-with-cross/circle-with-cross.svg.
  ERROR: `
<svg viewBox="0 0 40 40"><path d="M20 0C8.955 0 0 8.955 0 20s8.955 20 20 20 20-8.955 20-20S31.045 0 20 0Zm11.402 27.288-4.116 4.117L20 24.117l-7.288 7.285-4.117-4.116L15.885 20l-7.287-7.288 4.116-4.114L20 15.883l7.288-7.288 4.117 4.117L24.115 20l7.287 7.288Z"/></svg>
  `,
  //v Derived from src/dialog_icons/info-with-circle/info-with-circle.svg.
  INFO: `
<svg viewBox="0 0 40 40"><path d="M20.001 0C8.954 0 0 8.954 0 19.999 0 31.046 8.954 40 20.001 40 31.044 40 40 31.046 40 19.999 40 8.954 31.044 0 20.001 0Zm1.867 7.22c1.95 0 2.522 1.132 2.522 2.425 0 1.615-1.291 3.109-3.497 3.109-1.846 0-2.725-.928-2.671-2.463 0-1.294 1.081-3.07 3.646-3.07Zm-4.996 24.758c-1.333 0-2.306-.81-1.375-4.363l1.527-6.302c.265-1.008.308-1.412 0-1.412-.398 0-2.129.696-3.15 1.383l-.664-1.09c3.24-2.705 6.964-4.293 8.558-4.293 1.333 0 1.554 1.575.89 4l-1.75 6.625c-.311 1.17-.178 1.575.133 1.575.4 0 1.708-.484 2.995-1.498l.754 1.012c-3.152 3.15-6.587 4.363-7.918 4.363Z"/></svg>
  `,
  //v Derived from src/dialog_icons/help-with-circle/help-with-circle.svg.
  QUESTION: `
<svg viewBox="0 0 40 40"><path d="M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20c11.044 0 20-8.954 20-20.002C40 8.954 31.044 0 20 0Zm-.315 31.665h-.106c-1.629-.048-2.779-1.25-2.731-2.857.046-1.579 1.223-2.727 2.798-2.727l.096.004c1.675.048 2.812 1.238 2.764 2.89-.048 1.583-1.204 2.69-2.82 2.69Zm6.857-13.607c-.384.542-1.225 1.221-2.288 2.048l-1.17.806c-.642.5-1.03.973-1.174 1.434-.116.362-.17.46-.18 1.2v.187h-4.47l.013-.379c.056-1.55.094-2.466.737-3.223 1.01-1.183 3.24-2.62 3.334-2.681.32-.24.59-.512.79-.806.468-.648.674-1.156.674-1.652 0-.696-.204-1.34-.61-1.909-.392-.554-1.136-.829-2.21-.829-1.067 0-1.798.338-2.234 1.034-.45.71-.677 1.458-.677 2.222v.192h-4.606l.008-.2c.119-2.819 1.127-4.85 2.99-6.035 1.173-.752 2.633-1.134 4.335-1.134 2.225 0 4.109.542 5.588 1.609 1.502 1.08 2.262 2.702 2.262 4.814-.002 1.181-.375 2.292-1.112 3.302Z"/></svg>
  `,
  //v Derived from src/dialog_icons/warning-with-circle/warning-with-circle.svg.
  WARNING: `
<svg viewBox="0 0 40 40"><path d="M20.001 0C31.044 0 40 8.954 40 19.999 40 31.046 31.044 40 20.001 40 8.954 40 0 31.046 0 19.999 0 8.954 8.954 0 20.001 0Zm12.172 27.808L20.576 5.67a.9.9 0 0 0-1.573 0L7.408 27.808a.916.916 0 0 0 .006.905.9.9 0 0 0 .779.452h23.194a.9.9 0 0 0 .779-.452.919.919 0 0 0 .007-.905Zm-10.82-1.304H18.75v-2.661h2.604v2.661Zm0-4.656H18.75v-8.65h2.604v8.65Z"/></svg>
  `
};

export const icons = {
  //v Derived from src/icons/menu/menu.svg.
  CONTENTS: `
<svg viewBox="0 0 20 20"><path d="M16.4 9H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1Zm0 4H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1ZM3.6 7h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1Z"/></svg>
  `
};

export const miscellaneous = {
  X: `
<svg viewBox="0 0 20 20"><path d="M16.522 17.273a1.799 1.799 0 0 1-2.545 0L10 12.73l-3.976 4.543a1.799 1.799 0 1 1-2.546-2.545l4.137-4.725-4.139-4.729a1.799 1.799 0 1 1 2.546-2.545L10 7.274l3.976-4.546a1.799 1.799 0 1 1 2.545 2.546l-4.136 4.728 4.137 4.724a1.8 1.8 0 0 1 0 2.547Z"/></svg>
  `
};
