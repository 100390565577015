// These functions are conveniences. They have no external dependencies except Lodash functions, which are imported one by one as needed.

//v BEGIN: Some Lodash functions are built into many browsers these days. However, they're imported here if they're missing from recent versions of any major
//v browser, according to
//v
//v https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/ .
//v
//v (If in fact a function is built in, then the corresponding Lodash function should delegate to it.)
import _assign       from "lodash/assign";
import _cloneDeep    from "lodash/cloneDeep";
import _debounce     from "lodash/debounce";
import _defaults     from "lodash/defaults";
import _entries      from "lodash/entries";
import _flattenDeep  from "lodash/flattenDeep";
import _flattenDepth from "lodash/flattenDepth";
import _forEach      from "lodash/forEach";
import _forOwn       from "lodash/forOwn";
import _fromPairs    from "lodash/fromPairs";
import _includes     from "lodash/includes";
import _isEmpty      from "lodash/isEmpty";
import _isFunction   from "lodash/isFunction";
import _isNil        from "lodash/isNil";
import _isString     from "lodash/isString";
import _keys         from "lodash/keys";
import _mapKeys      from "lodash/mapKeys";
import _mapValues    from "lodash/mapValues";
import _pick         from "lodash/pick";
import _snakeCase    from "lodash/snakeCase";
import _values       from "lodash/values";
//^ END

export function addClass(klass, el) { if (!hasClass(klass, el)) el.className += ` ${klass}`; };

export function ajaxParams(object) { return snakeCaseKeys(compact(object)); };

export function assign(object, ...sources) { return _assign(object, ...sources); };

export function cloneDeep(object) { return _cloneDeep(object); };

//v As in Ruby, where both arrays and hashes are compactable. The argument should be an array or POJO, not something fancy like a map.
export function compact(arrayOrObject) {
  if (Array.isArray(arrayOrObject))
    return arrayOrObject.filter(function(value) { return !_isNil(value); });
  else {
    let result = _cloneDeep(arrayOrObject);
    _forOwn(result, function(value, key) { if (_isNil(value)) delete result[key]; });
    return result;
  }
};

export function debounce(funktion, wait = 0, options = {}) { return _debounce(funktion, wait, options); };

export function defaults(object, ...sources) { return _defaults(object, ...sources); };

export function emptyFunction() {};

//v As in Ruby.
export function flatten(array, depth = -1) {
  if (depth < 0)
    return _flattenDeep(array);
  else if (depth === 0)
    return array;
  else
    return _flattenDepth(array, depth);
};

//v This function differs from Array.prototype.forEach in that it terminates if iteratee returns false.
export function forEach(collection, iteratee) { return _forEach(collection, iteratee); };

export function forOwn(object, iteratee) { return _forOwn(object, iteratee); };

export function fromPairs(pairs) { return _fromPairs(pairs); };

//v Per
//v
//v https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block
//v
//v as of summer 2021, corrected as follows:
//v
//v   backdrop-filter also triggers containment in browsers that implement it, which Firefox and Safari don't. (Safari does implement -webkit-backdrop-filter,
//v   but it doesn't trigger containment.)
//v
//v   contain: paint doesn't trigger containment in Safari, which doesn't implement contain.
//v
//v   filter doesn't trigger containment in Safari.
//v
//v   perspective doesn't trigger containment in Safari.
//v
//v   will-change doesn't trigger containment in Safari.
//v
//v   will-change: filter triggers containment not only in Firefox but in other browsers (except Safari).
//v
//v   will-change: backdrop-filter also triggers containment in browsers that implement backdrop-filter.
//v
//v (In Safari, will-change doesn't trigger containment but may have bizarre effects. For example, will-change: -webkit-backdrop-filter, filter, or transform
//v with overflow: auto, hidden, or scroll may turn the styled element into a clipping region for an absolute-positioned child, as if the element became the
//v scroll parent of the child, but with overflow: auto, no scroll bars appear, and with overflow: scroll, empty scroll bars stay empty. Accordingly, using
//v will-change in Safari is risky.)
//v
//v (Note that under iOS, other browsers except Opera Mini behave like Safari in these regards, because under the hood, they more or less are Safari.)
//v
//v (By the way, that backdrop-filter, contain with value paint, filter, perspective, transform, and will-change with value backdrop-filter, contain, filter,
//v perspective, or transform can trigger containment is unintuitive and hence bad design. That different browsers implement containment differently is like a
//v throwback to the bad old days of Internet Explorer and Netscape. That all this apparently isn't documented accurately and completely anywhere adds insult
//v to injury. Under the circumstances, it seems prudent to avoid these properties and values as much as possible. In particular, it seems prudent to use box-
//v shadow instead of filter with value drop-shadow whenever a boxy shadow is acceptable; see
//v
//v https://css-tricks.com/breaking-css-box-shadow-vs-drop-shadow/ .)
export function getContainingBlockElementOfAbsolutePositionedChild(parentEl) {
  let
    el = parentEl,
    style = getComputedStyle(el);
  while (
    el !== document.body &&
    style.position === "static" &&
    (!style.backdropFilter || style.backdropFilter === "none") &&
    style.contain !== "paint" &&
    (window.browserIsSafari || window.browserIsSafariWebappMode || style.filter === "none") &&
    (window.browserIsSafari || window.browserIsSafariWebappMode || style.perspective === "none") &&
    style.transform === "none" &&
    (
      window.browserIsSafari ||
      window.browserIsSafariWebappMode ||
      (window.browserIsFirefox && !_includes(["contain", "filter", "perspective", "transform"], style.willChange)) ||
      (!window.browserIsFirefox && !_includes(["backdrop-filter", "contain", "filter", "perspective", "transform"], style.willChange))
    )
  ) {
    el = el.parentElement;
    style = getComputedStyle(el);
  }
  return el;
};

export function getScrollParentElementOfAbsolutePositionedChild(parentEl) {
  let
    el = getContainingBlockElementOfAbsolutePositionedChild(parentEl),
    style = getComputedStyle(el);
  while (el !== document.body && style.overflow === "visible" && style.overflowX === "visible" && style.overflowY === "visible") {
    el = el.parentElement;
    style = getComputedStyle(el);
  }
  return el;
};

export function getVisibleClientRect(boundingClientRect) {
  const
    top = Math.max(boundingClientRect.top, 0),
    bottom = Math.min(boundingClientRect.bottom, document.documentElement.clientHeight),
    height = bottom-top,
    left = Math.max(boundingClientRect.left, 0),
    right = Math.min(boundingClientRect.right, document.documentElement.clientWidth),
    width = right-left;
  //v Note that this isn't a DOMRect, and it doesn't have properties x and y, which a DOMRect has; see
  //v
  //v https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect .
  return {bottom, height, left, right, top, width};
};

export function hasClass(klass, el) { return _includes(el.className.split(/\s+/), klass); };

export function hoverIsSupported() { return window.matchMedia("(hover: hover)").matches; };

export function includes(collection, value) { return _includes(collection, value); };

export function isFunction(value) { return _isFunction(value); };

export function isNil(value) { return _isNil(value); };

export function isString(value) { return _isString(value); };

export function keys(object) { return _keys(object); };

//v Convenient for debugging in a Vue template, assuming these utilities are mixed in there.
export function log() { console.log.apply(null, arguments); };

export function mapValues(object, iteratee) { return _mapValues(object, iteratee); };

export function pathWithQuery(path, params) {
  let
    result = path,
    query = _entries(params)
      .map(
	function([key, value]) {
	  const name = _snakeCase(key);
	  return value !== null ? `${name}=${encodeURIComponent(value)}` : name;
	}
      )
      .join("&");
  if (!_isEmpty(query)) result = `${result}?${query}`;
  return result;
};

export function pick(object, paths) { return _pick(object, paths); };

export function removeClass(klass, el) {
  let
    classes = el.className.split(/\s+/),
    index = classes.indexOf(klass);
  if (index !== -1) {
    classes.splice(index, 1);
    el.className = classes.join(" ");
  }
};

export function scrollbarWidth(el) {
  if (el === document.body)
    return window.innerWidth-el.clientWidth;
  else {
    const style = getComputedStyle(el);
    return (
      el.offsetWidth-
      parseFloat(style.marginLeftWidth || 0)-
      parseFloat(style.marginRightWidth || 0)-
      parseFloat(style.borderLeftWidth || 0)-
      parseFloat(style.borderRightWidth || 0)-
      el.clientWidth
    );
  }
};

export function snakeCaseKeys(object) {
  return _mapKeys(object, function(value, key) { return _snakeCase(key); });
};

export function values(object) { return _values(object); };
