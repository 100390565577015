<!-- This component is meant to be invoked by application components. -->

<template>
  <div
    class="form-item form-input form-visorable form-field form-password-field"
    :class="{disabled: isDisabled, 'labels-at-left': labelsAreAtLeft, 'labels-on-top': labelsAreOnTop, 'with-visor': visorIsPresent}"
  >
    <div class="row-1">
      <FormLabel v-if="labelsAreOnTop" :help-string="helpString" :is-disabled="isDisabled" :label="label" labels-are-on-top/>
      <div v-if="note" class="note-container">
	<span class="note" v-html="note"/>
	<span v-if="labelsAreOnTop" class="separator">&ensp;|&ensp;</span>
      </div>
      <div class="modifiers-container">
	<span v-if="getLinkToResetPasswordPath">
	  <a :href="forgotPath">Forgot?</a>
	  <span class="separator">&ensp;|&ensp;</span>
	</span>
	<button tabindex="-1" type="button" @click="toggleInputIsMasked">Show/Hide</button>
      </div>
    </div>
    <div class="row-2">
      <div class="column-1">
	<FormLabel v-if="labelsAreAtLeft" :help-string="helpString" :is-disabled="isDisabled" :label="label"/>
      </div>
      <div class="column-2">
	<input
	  ref="input"
	  :autocomplete="autocomplete"
	  :placeholder="placeholder"
	  :spellcheck="false"
	  :type="type"
	  :value="value"
	  @blur="onBlur"
	  @input="onInput"
	  @keydown="signalReturn"
	>
	<FormErrorTip :validation-state="validationState"/>
      </div>
      <div class="column-3">
	<div v-if="labelsAreAtLeft && auxiliaryIsPresent" class="auxiliary">
	  <slot name="auxiliary-content"/>
	</div>
      </div>
    </div>
    <div class="row-3">
      <ShadeTopTransition mode="out-in">
	<div v-if="visorIsPresent" class="visor">
	  <slot name="visor-content"/>
	</div>
      </ShadeTopTransition>
    </div>
    <div class="row-4">
      <div v-if="labelsAreOnTop && auxiliaryIsPresent" class="auxiliary">
	<slot name="auxiliary-content"/>
      </div>
    </div>
  </div>
</template>

<script>
import formField from "./formField";

export default {
  name: "FormPasswordField",
  mixins: [formField],
  props: {
    //v If given, appended to getLinkToResetPasswordPath as query parameter.
    emailAddress:               {type: String, required: false},
    getLinkToResetPasswordPath: {type: String, required: false},
    //v If given, appended to getLinkToResetPasswordPath as query parameter.
    username:                   {type: String, required: false}
  },
  data() { return {inputIsMasked: true}; },
  computed: {
    forgotPath() {
      if (this.emailAddress)
	return utilities.pathWithQuery(this.getLinkToResetPasswordPath, {emailAddress: this.emailAddress});
      else if (this.username)
	return utilities.pathWithQuery(this.getLinkToResetPasswordPath, {username: this.username});
      else
	return this.getLinkToResetPasswordPath;
    },
    type() { return this.inputIsMasked ? "password" : "text"; }
  },
  methods: {
    onBlur() {
      this.rightTrimIfNeeded();
      this.touch();
    },
    onInput() {
      this.normalizeIfNeeded();
      this.leftTrimIfNeeded();
      this.touch();
    },
    toggleInputIsMasked() {
      const value = this.inputEl.value;
      this.inputIsMasked = !this.inputIsMasked;
      this.$nextTick(function() { this.inputEl.value = value; });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./formField";
</style>
