<!-- This component is meant to be invoked by application components. -->

<!-- Adapted from                                                             -->
<!--                                                                          -->
<!-- https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/ . -->

<script>
export default {
  name: "ShadeTopTransition",
  functional: true,
  props: {
    mode: {type: String, required: false}
  },
  render(createElement, context) {
    const data = {
      props: {mode: context.props.mode, name: "shade-top"},
      on: {
	afterEnter(el) {
	  el.style.height = "auto";
	  if (context.listeners["after-enter"]) context.listeners["after-enter"](el);
	},
	afterLeave(el) { if (context.listeners["after-leave"]) context.listeners["after-leave"](el); },
	beforeEnter(el) { if (context.listeners["before-enter"]) context.listeners["before-enter"](el); },
	beforeLeave(el) { if (context.listeners["before-leave"]) context.listeners["before-leave"](el); },
	enter(el) {
	  const width = getComputedStyle(el).width;
	  let height;
	  el.style.visibility = "hidden";
	  el.style.position = "absolute";
	  el.style.height = "auto";
	  el.style.width = width;
	  height = getComputedStyle(el).height;
	  el.style.visibility = null;
	  el.style.position = null;
	  el.style.height = 0;
	  el.style.width = null;
	  //v Force repaint with height 0.
	  getComputedStyle(el).height;
	  //v After repaint, set height to full, starting animation.
	  setTimeout(function() { el.style.height = height; });
	  if (context.listeners["enter"]) context.listeners["enter"](el);
	},
	enterCancelled(el) { if (context.listeners["enter-cancelled"]) context.listeners["enter-cancelled"](el); },
	leave(el) {
	  const height = getComputedStyle(el).height;
	  el.style.height = height;
	  //v Force repaint with full height.
	  getComputedStyle(el).height;
	  //v After repaint, set height to 0, starting animation.
	  setTimeout(function() { el.style.height = 0; });
	  if (context.listeners["leave"]) context.listeners["leave"](el);
	},
	leaveCancelled(el) { if (context.listeners["leave-cancelled"]) context.listeners["leave-cancelled"](el); }
      }
    };
    return createElement("transition", data, context.children);
  }
};
</script>
