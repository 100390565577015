// This file is meant to be mixed into application components. It's idempotent (effectively, albeit somewhat messily).

function viewportWidthIsAboveTabletThreshold() {
  //v See ../../../stylesheets/html.scss.
  return getComputedStyle(document.documentElement).getPropertyValue("--viewport-width-is-above-tablet-threshold").trim() === "1";
};

export default {
  data() { return {viewportWidthIsAboveTabletThreshold: false}; },
  computed: {viewportWidthIsBelowTabletThreshold() { return !this.viewportWidthIsAboveTabletThreshold; }},
  mounted() {
    this.v_setViewportWidthIsAboveTabletThreshold();
    window.addEventListener(
      "resize",
      //v utilities.debounce(this.v_setViewportWidthIsAboveTabletThreshold, 10) (or even 1 instead of 10) is apt to yield a flash of misstyled content.
      this.v_setViewportWidthIsAboveTabletThreshold
    );
  },
  beforeDestroy() { window.removeEventListener("resize", this.v_setViewportWidthIsAboveTabletThreshold); },
  methods: {v_setViewportWidthIsAboveTabletThreshold() { this.viewportWidthIsAboveTabletThreshold = viewportWidthIsAboveTabletThreshold(); }}
};
