// This file is meant to be mixed into application components. It's idempotent.

import TipComponent from "./TipComponent";

export default {
  created() { this.tips = {tip: this.t_tip}; },
  methods: {
    t_tip(openerEl, string, options = {}) {
      //^ string may contain HTML specifying inline elements but shouldn't contain HTML specifying block elements. Any user-supplied content should be HTML-
      //^ escaped, to thwart injection attacks.
      //^
      //^ Valid options include activeClass, offsetBottom, offsetLeft, offsetRight, offsetTop, and spurIsAtEdgeOfOpener.
      //^
      //^ If given, options.offsetBottom et al. should be CSS lengths (e.g., "0.0625rem"). Their purpose is to tweak the location of the tip relative to the
      //^ opener, so they should be small. They aren't taken into account when choosing an orientation or computing max-height, as described below.
      options = utilities.defaults(
	options,
	{activeClass: "active", offsetBottom: "0rem", offsetLeft: "0rem", offsetRight: "0rem", offsetTop: "0rem", spurIsAtEdgeOfOpener: false}
      );
      //v The main steps are as follows:
      //v
      //v   Choose an orientation - open downward or upward and rightward or leftward - based on the location of the opener relative to the visible part of the
      //v   scroll parent of the tip (which shouldn't be the opener itself).
      //v
      //v   Compute max-height - so that the tip scrolls if the visible part of its scroll parent would otherwise be too short - based on the location of the
      //v   opener relative to the visible part of the scroll parent. Note that wherever the tip opens on the screen, it will remain, as scrolling of the scroll
      //v   parent (and its ancestors) is disabled, so the tip must fit within the visible part of the scroll parent. This could lead to awkwardly squashed
      //v   tips. Avoiding that is up to the programmer. (The switch-to-fixed-positioning trick - see, for example,
      //v
      //v   https://stackoverflow.com/a/47134558
      //v
      //v   - could be used to make the tip "pop out of" its scroll parent, provided the containing block of the now-fixed-positioned tip is an ancestor of the
      //v   scroll parent. I (Ralph Haygood) consider this an ugly hack, and the proviso makes me nervous, so I'd rather not use it.)
      //v
      //v   Compute top or bottom and left or right - which two are required depends on the orientation - relative to the containing block of the tip (which
      //v   could be the opener itself).
      //v
      //v   Create the tip and add it to the DOM as a child of the opener.
      //v
      //v Note that when the tip closes, it destroys itself and removes itself from the DOM.
      const
	openerBoundingRectangle = openerEl.getBoundingClientRect(),
	openerVisibleRectangle = utilities.getVisibleClientRect(openerBoundingRectangle),
	openerMiddle = openerVisibleRectangle.top + openerVisibleRectangle.height/2,
	openerCenter = openerVisibleRectangle.left + openerVisibleRectangle.width/2,
	scrollParentEl = utilities.getScrollParentElementOfAbsolutePositionedChild(openerEl),
	scrollParentBoundingRectangle = scrollParentEl.getBoundingClientRect(),
	scrollParentVisibleRectangle = utilities.getVisibleClientRect(scrollParentBoundingRectangle),
	scrollParentMiddle = scrollParentVisibleRectangle.top + scrollParentVisibleRectangle.height/2,
	scrollParentCenter = scrollParentVisibleRectangle.left + scrollParentVisibleRectangle.width/2,
	containingBlockEl = utilities.getContainingBlockElementOfAbsolutePositionedChild(openerEl),
	containingBlockBoundingRectangle = containingBlockEl.getBoundingClientRect();
      let
	opensUpward = false,
	opensLeftward = false,
	//v BEGIN: These values are passed to TipComponent, which adjusts them slightly to accommodate the spur.
	maxHeight,
	bottom,
	top,
	right,
	left;
	//^ END
      if (scrollParentEl === openerEl) {
	//^ Shouldn't happen, but handle gracefully.
	console.log("ERROR: Opener");
	console.log(openerEl);
	console.log("would be scroll parent of tip.");
      }
      if (openerMiddle > scrollParentMiddle) {
	opensUpward = true;
	maxHeight = openerVisibleRectangle.top-scrollParentVisibleRectangle.top;
	bottom = ((containingBlockBoundingRectangle.bottom-containingBlockEl.scrollTop) - openerBoundingRectangle.bottom) + openerBoundingRectangle.height;
      } else {
	maxHeight = scrollParentVisibleRectangle.bottom-openerVisibleRectangle.bottom;
	top = (openerBoundingRectangle.top - (containingBlockBoundingRectangle.top-containingBlockEl.scrollTop)) + openerBoundingRectangle.height;
      }
      if (openerCenter > scrollParentCenter) {
	opensLeftward = true;
	right = containingBlockBoundingRectangle.right-openerBoundingRectangle.right;
	if (!options.spurIsAtEdgeOfOpener) right += openerBoundingRectangle.width/2;
      } else {
	left = openerBoundingRectangle.left-containingBlockBoundingRectangle.left;
	if (!options.spurIsAtEdgeOfOpener) left += openerBoundingRectangle.width/2;
      }
      new Promise(
	(resolve) => {
	  const
	    TipComponentConstructor = Vue.extend(TipComponent),
	    tip = new TipComponentConstructor(
	      {
		parent: this,
		propsData: {
		  bottom,
		  left,
		  maxHeight,
		  offsetBottom: options.offsetBottom,
		  offsetLeft: options.offsetLeft,
		  offsetRight: options.offsetRight,
		  offsetTop: options.offsetTop,
		  openerEl,
		  opensLeftward,
		  opensUpward,
		  resolve,
		  right,
		  spurIsAtEdgeOfOpener: options.spurIsAtEdgeOfOpener,
		  string,
		  top
		}
	      }
	    );
	  tip.$mount();
	  openerEl.appendChild(tip.$el);
	  utilities.addClass(options.activeClass, openerEl);
	}
      )
	.finally(function() { utilities.removeClass(options.activeClass, openerEl); });
    }
  }
};
