// This file is meant to be mixed into formInputs; see ./README. [Unneeded features have been deleted.]

// Conceptually, this scheme owes something to Vuelidate,
//
// https://vuelidate.js.org/ ,
//
// which was used in early versions of these form components. However, Vuelidate has a nasty defect, of which the maintainers have been advised repeatedly and
// which they've tried to correct repeatedly, but as of spring 2021, it's still there; see
//
// https://github.com/vuelidate/vuelidate/issues/302
//
// and
//
// https://github.com/vuelidate/vuelidate/issues/460 .
//
// I (Ralph Haygood) took a look at the Vuelidate code, thinking to correct the defect myself, and ... it's amazingly complicated, much more so than I see any
// good reason for it to be. So I replaced Vuelidate with my own scheme. Ultimately, I prefer this scheme, which I consider more perspicuous. For example, it
// uses props to indicate which validations should be applied to which form components, instead of a seperate validations option in the script section of the
// parent component.

// The props that indicate which validations should be applied - shouldBeChecked, shouldBeEmailAddress, etc. - are treated as constants, in that changing their
// values doesn't trigger revalidation; only changing the value of the formInput triggers revalidation. Triggering revalidation on prop changes would be easy,
// but it doesn't seem very useful, and it would require watching the props, which would cost a bit of memory and time.

const
  //v Should match Subscriber::EMAIL_ADDRESS_LENGTH_MAX; see app/models/subscriber.rb. See
  //v
  //v http://tools.ietf.org/html/rfc5321 .
  //v
  //v Needed because EMAIL_ADDRESS_PATTERN_ACCEPTED is lenient.
  EMAIL_ADDRESS_LENGTH_MAX = 254,
  //v Should match Subscriber::EMAIL_ADDRESS_PATTERN_ACCEPTED; see app/models/subscriber.rb. Deliberately lenient, because email-address syntax is insanely
  //v overcomplicated.
  EMAIL_ADDRESS_PATTERN_ACCEPTED = /^\S+@\S+\.\S+$/;

export default {
  props: {
    //v BEGIN: Relevant to FormPasswordField, FormTextArea, and FormTextField only.
    lengthShouldBeAtMost:  {type: Number,  required: false},
    lengthShouldBeAtLeast: {type: Number,  required: false},
    //^ END
    //v Relevant to FormTextField only.
    shouldBeEmailAddress:  {type: Boolean, required: false},
    shouldBePresent:       {type: Boolean, required: false},
    validationState:       {type: Object,  required: false}
  },
  mounted() {
    if (this.validationState) {
      this.fV_validate();
      this.$watch("value", this.fV_validate);
    }
  },
  methods: {
    //v Should match assumptions of ValidationState and the FormErrorTip template.
    fV_validate() {
      let fails = false;
      if (this.lengthShouldBeAtLeast) {
	if (!utilities.isString(this.value) || this.value.length >= this.lengthShouldBeAtLeast)
	  this.validationState.failsLengthAtLeast = false;
	else {
	  fails = this.validationState.failsLengthAtLeast = true;
	  this.validationState.lengthAtLeast = this.lengthShouldBeAtLeast;
	}
      }
      if (this.lengthShouldBeAtMost) {
	if (!utilities.isString(this.value) || this.value.length <= this.lengthShouldBeAtMost)
	  this.validationState.failsLengthAtMost = false;
	else {
	  fails = this.validationState.failsLengthAtMost = true;
	  this.validationState.lengthAtMost = this.lengthShouldBeAtMost;
	}
      }
      if (this.shouldBeEmailAddress) {
	if (!utilities.isString(this.value) || (this.value.length <= EMAIL_ADDRESS_LENGTH_MAX && this.value.match(EMAIL_ADDRESS_PATTERN_ACCEPTED)))
	  this.validationState.failsEmailAddress = false;
	else
	  fails = this.validationState.failsEmailAddress = true;
      }
      if (this.shouldBePresent) {
	if (!utilities.isNil(this.value) && this.value !== "")
	  this.validationState.failsPresent = false;
	else
	  fails = this.validationState.failsPresent = true;
      }
      this.validationState.isValid = !fails;
    }
  }
};
