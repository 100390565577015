<!-- This component is meant to be invoked by the dialogs mixin. -->

<template>
  <!-- If the dialog is wide, and the screen width isn't above the tablet threshold, the dialog is fullscreen, presumably on a phone. (That's the logic of -->
  <!-- the isFullscreen computed property. Why not use the latter here? Because it depends on the DOM, which isn't available yet.) If, morover, the dialog -->
  <!-- contains a form, fading the dialog in and out is apt to clash with the coming and going of a virtual keyboard.                                      -->
  <transition
    :name="(isWide && contentComponentContainsFormField) ? 'fade-fast-unless-phone' : 'fade-fast'"
    @after-enter="addBodyWrapperToScrollableElements"
    @after-leave="selfDestruct"
    @before-leave="deleteBodyWrapperFromScrollableElements"
  >
    <div v-if="isVisible" class="dialog">
      <div class="scrim" :class="{fullscreen: isFullscreen, 'not-fullscreen': !isFullscreen}"/>
      <div ref="box" class="box" :class="{fullscreen: isFullscreen, 'height-specified': !!height, wide: isWide}">
	<div v-if="heading" class="header">
	  <div class="heading" v-html="heading"/>
	  <button class="close-button" type="button" @click="close(null)">
	    <div class="icon" v-html="svgs.miscellaneous.X"/>
	  </button>
	</div>
	<div ref="body-wrapper" class="body-wrapper" :class="{'no-header': !heading}">
	  <div class="body" :class="{'right-margin-should-exclude-scrollbar': rightMarginShouldExcludeScrollbar}">
	    <div v-if="iconKind" class="icon-container">
	      <div v-if="iconKind === 'error'" key="error" class="icon" v-html="svgs.dialogIcons.ERROR"/>
	      <div v-else-if="iconKind === 'info'" key="info" class="icon" v-html="svgs.dialogIcons.INFO"/>
	      <div v-else-if="iconKind === 'question'" key="question" class="icon" v-html="svgs.dialogIcons.QUESTION"/>
	      <div v-else key="warning" class="icon" v-html="svgs.dialogIcons.WARNING"/>
	    </div>
	    <div v-if="contentMainString" class="content-string-container" v-html="paragraphedContentString"/>
	    <div v-else class="content-component-instance-container">
	      <component
		:is="contentComponentOptions"
		ref="content-component-instance"
		v-bind="contentComponentPropsData"
		@close="close"
		@enable-or-disable-go-button="enableOrDisableGoButton"
	      />
	    </div>
	  </div>
	</div>
	<div v-if="kind === 'alert'" key="alert" class="footer">
	  <button ref="go-button" class="go-button ok-button" type="button" @click="close(true)" v-html="okButtonLabel"/>
	</div>
	<div v-else key="confirm" class="footer">
	  <button ref="go-button" class="go-button yes-button" type="button" @click="close(true)" v-html="yesButtonLabel"/>
	  <button class="stop-button no-button" type="button" @click="close(false)" v-html="noButtonLabel"/>
	</div>
      </div>
    </div>
  </transition>
</template>

<script>
import viewport from "../viewport/viewport";

const
  BEGIN_MAIN_P = "<p>",
  BEGIN_NOTE_P = "<p class='note'>",
  END_P = "</p>";

export default {
  //v DialogComponent rather than Dialog, because single-word component names can be problematic.
  name: "DialogComponent",
  mixins: [viewport],
  props: {
    beforeClose:                       {type: Function, required: true },
    contentComponentContainsFormField: {type: Boolean,  required: false},
    contentComponentOptions:           {type: Object,   required: false},
    contentComponentPropsData:         {type: Object,   required: false},
    contentMainString:                 {type: String,   required: false},
    contentNoteString:                 {type: String,   required: false},
    heading:                           {type: String,   required: false},
    //v A CSS length (e.g., "90vh"). Applies only when not fullscreen. Assumed constant.
    height:                            {type: String,   required: false},
    //v "error", "info", "question", or "warning".
    iconKind:                          {type: String,   required: false},
    isWide:                            {type: Boolean,  required: false},
    //v "alert" or "confirm".
    kind:                              {type: String,   required: true },
    noButtonLabel:                     {type: String,   required: true },
    okButtonLabel:                     {type: String,   required: true },
    resolve:                           {type: Function, required: true },
    rightMarginShouldExcludeScrollbar: {type: Boolean,  required: false},
    yesButtonLabel:                    {type: String,   required: true }
  },
  data() { return {isVisible: false}; },
  computed: {
    isFullscreen() { return this.isWide && this.viewportWidthIsBelowTabletThreshold; },
    paragraphedContentString() {
      const
	paragraphedContentMainString = BEGIN_MAIN_P+this.contentMainString.replace(/(\r?\n)+/g, END_P+BEGIN_MAIN_P)+END_P,
	paragraphedContentNoteString = this.contentNoteString ? BEGIN_NOTE_P+this.contentNoteString.replace(/(\r?\n)+/g, END_P+BEGIN_NOTE_P)+END_P : "";
      return paragraphedContentMainString+paragraphedContentNoteString;
    }
  },
  mounted() {
    utilities.forEach(document.querySelectorAll("input"), function(inputEl) { inputEl.blur(); });
    this.isVisible = true;
    //v Prop height is assumed constant.
    if (this.height) this.$nextTick(() => { this.$refs.box.style.setProperty("--box-height", this.height); });
    this.$store.dispatch("scrollableElements/addFreezeRequest", this);
    document.addEventListener("keydown", this.onReturnOrEscapeCloseIfCurrent);
  },
  beforeDestroy() {
    this.$store.dispatch("scrollableElements/deleteFreezeRequest", this);
    document.removeEventListener("keydown", this.onReturnOrEscapeCloseIfCurrent);
  },
  methods: {
    addBodyWrapperToScrollableElements() { this.$store.dispatch("scrollableElements/addScrollableElement", this.$refs["body-wrapper"]); },
    close(signal) {
      //v Delay is needed, else blur handlers on form elements within the content component may not run properly, for reasons unclear.
      setTimeout(() => { this.isVisible = !this.beforeClose(signal, this.resolve, this.$refs["content-component-instance"]); });
    },
    deleteBodyWrapperFromScrollableElements() { this.$store.dispatch("scrollableElements/deleteScrollableElement", this.$refs["body-wrapper"]); },
    enableOrDisableGoButton(shouldEnable) {
      const goButtonEl = this.$refs["go-button"];
      if (shouldEnable)
	utilities.removeClass("disabled", goButtonEl);
      else
	utilities.addClass("disabled", goButtonEl);
    },
    onReturnOrEscapeCloseIfCurrent(event) {
      if (!this.$el.nextElementSibling) {
	//^ TODO: Brittle, in that this assumes a next element sibling is a newer dialog, which works because the dialog function appends dialogs to the body
	//^ element, but is there a better way?
	if (event.key === "Enter")
	  this.close(true);
	else if (event.key === "Escape")
	  this.close(null);
      }
    },
    selfDestruct() {
      this.$destroy();
      this.$el.parentElement.removeChild(this.$el);
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog {
  /*v Make root z-indexable. */
  @apply relative;
  /*v In document stacking context. Above all (except subsequent dialogs or flickers). */
  @apply z-50;

  .scrim {
    &.fullscreen {
      @apply hidden;
    }

    &:not(.fullscreen) {
      @apply fixed top-0 left-0 h-full w-full bg-scrim--background;
    }
  }

  .box {
    &.fullscreen {
      --box-width: 100%;
    }

    &:not(.fullscreen) {
      &.wide {
	/*^ not(.fullscreen) and .wide implies "@screen tablet". */
	/*v Second argument should be 89% of max-width of #content; see ../../../stylesheets/content.scss. */
	--box-width: calc(min(89vw, 41rem)); /* 656px */
      }

      &:not(.wide) {
	--box-width: 18rem; /* 288px */
	@screen tablet {
	  --box-width: 24rem; /* 384px */
	}
      }
    }

    @apply fixed flex flex-col;
    width: var(--box-width);

    &.fullscreen {
      @apply top-0 left-0 h-full;
    }

    &:not(.fullscreen) {
      @apply top-1/2 left-1/2;
      transform: translate(-50%, -50%);
      @apply box-shadows;
      border-radius: 0.1875rem; /* 3px */
      /*v Works around a defect of Chrome. The defect is in the implementation of clip-path, which is applied to .header and .footer below. Intermittently, a */
      /*v row or column of pixels is clipped from an edge with inset 0, exposing the background of .box.                                                      */
      @apply bg-chrome--background-1;

      &.height-specified {
	height: var(--box-height);
      }

      &:not(.height-specified) {
	/*v Ad hoc. */
	max-height: 90vh;
      }
    }

    .header {
      /*v Make header z-indexable. */
      @apply relative;
      /*v In root stacking context. Above .body-wrapper. */
      @apply z-10;
      @apply flex justify-between items-center box-shadows;
      height: 2.625rem; /* 42px */
      @screen tablet {
	height: 3.5rem; /* 56px */
      }
      border-radius: inherit;
      @apply rounded-b-none bg-chrome--background-1;
      padding: 0 0.9375rem; /* 0 15px */
      @screen tablet {
	padding: 0 1.25rem; /* 0 20px */
      }

      .heading {
	@apply text-chrome--text font-bold;
	@apply text-lg-phone;
	@screen tablet {
	  @apply text-lg;
	}
	@apply leading-none;
      }

      .close-button {
	/*v Ad hoc. */
	margin-right: -0.125rem; /* -2px */

	.icon {
	  height: 0.75rem; /* 12px */
	  width: 0.75rem; /* 12px */
	  @screen tablet {
	    height: 1rem; /* 16px */
	    width: 1rem; /* 16px */
	  }
	  @apply text-chrome--text if-hover-is-supported:hover:text-chrome-control--text-hover fill-current;
	}
      }
    }

    &:not(.fullscreen) {
      .header {
	/*v Confines the box-shadows to the bottom edge, else they would deepen the shadows around .box. The -1rem is quasi-arbitrary; the magnitude simply */
	/*v must be great enough to accommodate the shadows.                                                                                                */
	clip-path: inset(0 0 -1rem 0 round 0.1875rem 0.1875rem 0 0); /* 0 0 -16px 0 round 3px 3px 0 0 */
      }
    }

    .body-wrapper {
      @apply flex-grow overflow-auto;
      scroll-behavior: smooth;
      @apply bg-content--background-1;

      &.no-header {
	border-radius: inherit;
	@apply rounded-b-none;
      }

      .body {
	@apply flex justify-start items-start;
	width: calc(var(--box-width) - var(--scrollbar-width));
	border-radius: inherit;
	@apply overflow-visible;
	padding: 0.9375rem; /* 15px */
	@screen tablet {
	  padding: 1.25rem; /* 20px */
	}

	&:not(.right-margin-should-exclude-scrollbar) {
          padding-right: calc(max(calc(0.9375rem - var(--scrollbar-width)), 0px)); /* 15px */
	  @screen tablet {
            padding-right: calc(max(calc(1.25rem - var(--scrollbar-width)), 0px)); /* 20px */
	  }
	}

	.icon-container {
	  margin-bottom: 0.375rem; /* 6px */
	  padding-right: 0.75rem; /* 12px */
	  @screen tablet {
	    margin-bottom: 0.5rem; /* 8px */
	    padding-right: 1rem; /* 16px */
	  }

	  .icon {
	    @apply flex-shrink-0;
	    height: 2.25rem; /* 36px */
	    width: 2.25rem; /* 36px */
	    @screen tablet {
	      height: 3rem; /* 48px */
	      width: 3rem; /* 48px */
	    }
	  }
	}

	.content-string-container {
	  /deep/ p {
	    margin-bottom: 0.375rem; /* 6px */
	    @apply text-md-phone leading-md-phone;
	    @screen tablet {
	      margin-top: 0.5rem; /* 8px */
	      @apply text-md leading-md;
	    }

	    &:first-child {
	      @apply mt-0;
	    }

	    &.note {
	      @apply text-sm-phone leading-sm-phone;
	      @screen tablet {
		@apply text-sm leading-sm;
	      }
	    }
	  }
	}

	.content-component-instance-container {
	  @apply w-full;
	}
      }
    }

    .footer {
      /*v Make footer z-indexable. */
      @apply relative;
      /*v In root stacking context. Above .body-wrapper. (This isn't strictly needed, as .body-wrapper is earlier in the document flow, but it doesn't hurt.) */
      @apply z-10;
      @apply flex justify-end items-center box-shadows;
      height: 2.625rem; /* 42px */
      @screen tablet {
	height: 3.5rem; /* 56px */
      }
      border-radius: inherit;
      @apply rounded-t-none bg-chrome--background-1;

      button {
	margin-right: 0.65625rem; /* 10.5px */
	height: 1.3125rem; /* 21px */
	width: 3.75rem; /* 60px */
	@screen tablet {
	  margin-right: 0.875rem; /* 14px */
	  height: 1.75rem; /* 28px */
	  width: 5rem; /* 80px */
	}
	@apply rounded-full text-button--text if-hover-is-supported:hover:text-button--text-hover font-bold;
	@apply text-base-phone;
	/*v "@apply leading-none" places the label 1px too far down, at least in Chrome, for reasons unfathomable. */
	line-height: 1.3125rem; /* 21px */
	@screen tablet {
	  @apply text-base leading-none;
	}
	@apply font-sans-narrow;

	&.go-button {
	  @apply bg-button-go--background if-hover-is-supported:hover:bg-button-go--background-hover;

	  &.disabled {
	    @apply opacity-50 pointer-events-none bg-button--background-disabled text-button--text-disabled;
	  }
	}

	&.stop-button {
	  @apply bg-button-stop--background if-hover-is-supported:hover:bg-button-stop--background-hover;
	}
      }
    }

    &:not(.fullscreen) {
      .footer {
	/*v Confines the box-shadows to the top edge, else they would deepen the shadows around .box. The -1rem is quasi-arbitrary; the magnitude simply must */
	/*v be great enough to accommodate the shadows.                                                                                                       */
	clip-path: inset(-1rem 0 0 0 round 0 0 0.1875rem 0.1875rem); /* -16px 0 0 0 round 0 0 3px 3px */
      }
    }
  }
}
</style>
