<!-- See app/views/subscribers/home.html.erb. -->

<template>
  <form class="sign-up-form" @submit.prevent="validateAndSignUp">
    <FormTextField
      ref="email-address-field"
      input-mode="email"
      is-lower-case
      is-trimmed
      label="Email address"
      normalization="NFC"
      note="Lowercase only"
      should-be-email-address
      should-be-present
      :validation-state="validationGroup.emailAddress"
      v-model="emailAddress"
      @return="validateAndSignUp"
    />
    <FormSubmit
      ref="sign-up-button"
      :is-disabled="validationGroup.isEngaged && !validationGroup.isValid"
      label="Sign up"
    />
  </form>
</template>

<script>
import FormSubmit from "../shared/forms/FormSubmit";
import FormTextField from "../shared/forms/FormTextField";

import dialogs from "../shared/dialogs/dialogs";

import forms from "../shared/forms/forms";

import ValidationGroup from "../shared/forms/ValidationGroup";

export default {
  name: "SignUpForm",
  components: {FormSubmit, FormTextField},
  mixins: [dialogs, forms],
  data() { return {emailAddress: null, validationGroup: new ValidationGroup(["emailAddress"])}; },
  mounted() {
    //v Convenient for debugging in a browser console.
    window.signUpForm = this;
  },
  beforeDestroy() { delete window.signUpForm; },
  methods: {
    validateAndSignUp() {
      this.validationGroup.engage();
      if (!this.validationGroup.isValid) return;
      Axios.post(paths.SIGN_UP_FOR_VISITOR, utilities.ajaxParams({emailAddress: this.emailAddress}))
	.then(
	  () => {
	    this.dialogs.info(
	      [
		"Thanks for signing up!",
		"If you ever decide you don’t want to receive newsletters anymore, simply follow the Unsubscribe link in any newsletter you’ve received."
	      ]
	    );
	    this.validationGroup.disengage();
	    this.emailAddress = null;
	  }
	)
	.catch((error) => { this.dialogs.ajaxError(error); });
    }
  }
};
</script>
