// This file is meant to be mixed into formButtons; see ./README.

export default {
  props: {
    classes:        {type: String,  required: false},
    isDisabled:     {type: Boolean, required: false},
    //v May contain HTML specifying inline elements but shouldn't contain HTML specifying block elements. Any user-supplied content should be HTML-escaped, to
    //v thwart injection attacks.
    label:          {type: String,  required: true },
    labelsAreOnTop: {type: Boolean, required: false}
  },
  computed: {labelsAreAtLeft() { return !this.labelsAreOnTop; }}
};
