import * as utilities from "./utilities";

//v See ../stylesheets/manifest.scss.
export function closeIfPresentAndOpenOnPageLeave() {
  const manifestEl = document.querySelector("#manifest");
  if (manifestEl && utilities.hasClass("is-open", manifestEl))
    return new Promise(
      function(resolve) {
	utilities.removeClass("is-open", manifestEl);
	manifestEl.addEventListener("transitionend", resolve, {once: true});
      }
    );
  else
    return Promise.resolve();
};

export function toggle() {
  const manifestEl = document.querySelector("#manifest");
  if (manifestEl) {
    //^ Paranoia.
    if (utilities.hasClass("is-open", manifestEl))
      utilities.removeClass("is-open", manifestEl);
    else
      utilities.addClass("is-open", manifestEl);
  }
};
